import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

export interface PeriodicElement {
  site: string;
  city: string;
  state: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  //{site: 'TOUCH N GO CUSTOMER EXPERIENCE CENTRE', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'TNG HUB MOBILE 1', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL DESA AMAN PURI', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL JALAN KUCHAI LAMA 2', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL SUNGAI LONG 2', city: 'KAJANG', state: 'SELANGOR'},
  //{site: 'SHELL NKVE SUBANG TOLL', city: 'PETALING JAYA', state: 'SELANGOR'},
  //{site: 'PLAZA LOW YAT', city: 'BUKIT BINTANG', state: 'KUALA LUMPUR'},
  { site: 'D PULZE CYBERJAYA, DTOX', city: 'CYBERJAYA', state: 'SELANGOR' },
  { site: 'SUBANG EMPIRE, DTOX', city: 'SUBANG JAYA', state: 'SELANGOR' },
  { site: 'THE MINES SHOPPING MALL', city: 'SERI KEMBANGAN', state: 'SELANGOR' },
  { site: 'VIGOR AUTO SDN BHD', city: 'BANDAR BUKIT RAJA', state: 'SELANGOR' },
  { site: 'AUTOCITY PENANG', city: 'PERAI', state: 'PULAU PINANG' },
  { site: 'BSS RESOURCES MANAGEMENT', city: 'BAYAN LEPAS', state: 'PULAU PINANG' },
  { site: 'GIANT KELANA JAYA', city: 'KELANA JAYA', state: 'SELANGOR' },
  //{ site: 'MUTIARA HEIGHTS', city: 'BANDAR JELUTONG', state: 'PULAU PINANG' },
  //{ site: 'LIM TAYAR OLD KLANG ROAD', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR' },

  { site: 'DTOX CAR SERVICE WANGSA WALK', city: 'WANGSA MAJU', state: 'KUALA LUMPUR' },
  { site: 'BOK TYRE AND BATTERY', city: 'BAYAN LEPAS', state: 'PULAU PINANG' },
  { site: 'PIT STOP AUTO CARE', city: 'JOHOR BAHRU', state: 'JOHOR' },
  { site: 'SS TYRE AND MOTORCRAFT ENTERPRISE', city: 'GEORGETOWN', state: 'PULAU PINANG' },
  { site: 'WH ONEPLUS AUTO', city: 'BANDAR BARU BANGI', state: 'SELANGOR' },
  { site: 'BEST TUNE AUTO WORKSHOP', city: 'SUNGAI JAWI', state: 'PULAU PINANG' },
  { site: 'ROEY AUTO SERVICES DENAI ALAM', city: 'SHAH ALAM', state: 'SELANGOR' },
  { site: 'ZK TYRE AUTO SERVICE', city: 'ALOR SETAR', state: 'KEDAH' },
  { site: 'LIM TAYAR HULU KLANG', city: 'AMPANG', state: 'SELANGOR' },
  { site: 'CHIA AUTO CENTER PANDAN INDAH ', city: 'PANDAN INDAH', state: 'KUALA LUMPUR' },
  { site: 'LIM TAYAR KEPONG', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR' },
  { site: 'LIM TAYAR SEREMBAN 2', city: 'SEREMBAN', state: 'NEGERI SEMBILAN' },
  { site: 'LIM TAYAR SUBANG JAYA', city: 'SUBANG JAYA', state: 'SELANGOR' },
  //{ site: 'DTOX CAR SERVICE USJ 21', city: 'SUBANG JAYA', state: 'SELANGOR' },
  { site: 'DTOX CAR SERVICE PJ8', city: 'PETALING JAYA', state: 'SELANGOR' },
  { site: 'JJB AUTO SERVICE CENTER', city: 'JOHOR BAHRU', state: 'JOHOR' },
  //{ site: 'FXQ SUCCESS TRADING', city: 'SUNGAI PETANI', state: 'KEDAH' },
  { site: 'UMW TOYOTSU MOTORS RAWANG', city: 'RAWANG', state: 'SELANGOR' },
  { site: 'UMW TOYOTSU MOTORS SHAH ALAM', city: 'SHAH ALAM', state: 'SELANGOR' },
  //{ site: 'ARAZZ GROUP OF COMPANIES', city: 'SUNGAI BULOH', state: 'SELANGOR' },
  { site: 'LIM TAYAR SENTUL', city: 'SENTUL', state: 'KUALA LUMPUR' },
  //{ site: 'SOAR CAR WASH', city: 'SERI KEMBANGAN', state: 'SELANGOR' },
  //{ site: 'SPEED HANDS CAR WASH', city: 'KLANG', state: 'SELANGOR' },
  //{ site: "LOTUS'S KULAI", city: 'KULAI', state: 'JOHOR' },
  { site: 'DUKE RNR TOL SEGAMBUT', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR' },
  { site: 'HAWK TYRE SERVICE SDN BHD (HEAD OFFICE)', city: 'JOHOR BAHRU', state: 'JOHOR' },
  { site: 'HAWK TYRE SERVICE SDN BHD (SHELL TAMAN SRI TEBRAU)', city: 'JOHOR BAHRU', state: 'JOHOR' },
  { site: 'HAWK TYRE SETIA TROPIKA', city: 'JOHOR BAHRU', state: 'JOHOR' },
  //{ site: 'V PRO CAR ACCESSORIES', city: 'PUCHONG', state: 'SELANGOR' },
  { site: 'EZZE AUTO MARKETING SDN BHD', city: 'WANGSA MAJU', state: 'KUALA LUMPUR' },
  { site: 'PLUS SUNGAI DUA TOLL PLAZA', city: 'PERAI', state: 'PULAU PINANG' },
  { site: 'PLUS HUTAN KAMPUNG TOLL PLAZA', city: 'ALOR SETAR', state: 'KEDAH' },
  //{ site: 'ECO BOTANIC RFID', city: 'GELANG PATAH', state: 'JOHOR' },
  { site: 'PETRONAS CHANGKAT JONG', city: 'TELUK INTAN', state: 'PERAK' },
  { site: 'PETRONAS LEBUHRAYA SULTAN ABDUL HALIM', city: 'ALOR SETAR', state: 'KEDAH' },
  //{ site: 'PETRON KULAI SENAI', city: 'KULAI', state: 'JOHOR' },
  //{ site: 'PLUS - PUSAT KHIDMAT PELANGGAN JAMBATAN PULAU PINANG (JPP)', city: 'PERAI', state: 'PULAU PINANG' },
  //{ site: 'PLUS BERTAM TOLL PLAZA', city: 'KEPALA BATAS', state: 'PULAU PINANG' },
  //{ site: 'PLUS KUBANG SEMANG TOLL PLAZA', city: 'PERMATANG PAUH', state: 'PULAU PINANG' },
  //{ site: 'PLUS R&R GURUN NORTH BOUND', city: 'GURUN', state: 'KEDAH' },
  //{ site: 'PLUS R&R GURUN SOUTH BOUND', city: 'GURUN', state: 'KEDAH' },
  //{ site: 'PLUS SUNGAI PETANI SELATAN TOLL PLAZA', city: 'SUNGAI PETANI', state: 'KEDAH' },
  //{ site: 'PLUS ALOR SETAR UTARA TOLL PLAZA', city: 'ALOR SETAR', state: 'KEDAH' },

  /*{site: 'SUCCESS SERVICE AND MAINTENANCE', city: 'JOHOR BAHRU', state: 'JOHOR'},
  {site: 'SETIA GEMILANG AUTO', city: 'SHAH ALAM', state: 'SELANGOR'},
  {site: 'TESCO KULAI', city: 'KULAI', state: 'JOHOR'},
  {site: 'ELITE MOTORSPORT SERVICE', city: 'BATU BERENDAM', state: 'MELAKA'},
  {site: 'BSS RESOURCE MANAGEMENT', city: 'BAYAN LEPAS', state: 'PULAU PINANG'},
  {site: 'PARADIGM MALL', city: 'PETALING JAYA', state: 'SELANGOR'},
  {site: 'AEON BIG KEPONG', city: 'KEPONG', state: 'KUALA LUMPUR'},
  {site: 'AEON BIG BANDAR TUN HUSSEIN ONN', city: 'CHERAS', state: 'SELANGOR'},
  {site: 'PROTON EDAR PLENTONG', city: 'JOHOR BAHRU', state: 'JOHOR'},
  {site: 'MAXIMUS AUTO CAR CARE SERVICE', city: 'KOTA DAMANSARA', state: 'SELANGOR'},
  {site: 'BSS WEALTH CONSULTANCY', city: 'SUNGAI PETANI', state: 'KEDAH'},*/
  // {site: 'TESCO BUKIT PUCHONG', city: 'BANDAR BUKIT PUCHONG', state: 'SELANGOR'},
  //{site: 'THE STARLING MALL', city: 'DAMANSARA UTAMA', state: 'SELANGOR'},
  //{site: 'STAR AVENUE LIFESTYLE MALL', city: 'SHAH ALAM', state: 'SELANGOR'},
  //{site: 'SHELL WANGSA MAJU 2', city: 'WANGSA MAJU', state: 'KUALA LUMPUR'},
  //{site: 'SHELL JALAN CHERAS KM5.5', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL PERSIARAN KAYANGAN SEK. 7', city: 'SHAH ALAM', state: 'SELANGOR'},
  //{site: 'SHELL JELUTONG EXPRESSWAY', city: 'GEORGETOWN', state: 'PULAU PINANG'},
  //{site: 'SHELL BANDAR UTAMA', city: 'DAMANSARA', state: 'SELANGOR'},
  //{site: 'SHELL TAMAN CONNAUGHT', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL KEPONG 2', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL MRR 2 AMPANG', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL PANDAN MEWAH', city: 'AMPANG', state: 'SELANGOR'},
  //{site: 'SHELL PUTRA HEIGHTS', city: 'SUBANG JAYA', state: 'SELANGOR'},
  //{site: 'E MAX CAR ACCESSORIES ', city: 'TAMAN PERMATA', state: 'KUALA LUMPUR'},
  //{site: 'HAWK TYRE SERVICE (TYREPLUS DESA TEBRAU)', city: 'TEBRAU', state: 'JOHOR'},
  //{site: 'HAWK TYRE SERVICE (TYREPLUS SETIA TROPIKA)', city: 'JOHOR BAHRU', state: 'JOHOR'},
  //{site: 'H20 HOUSE OF TYRES AND RIMS SDN BHD', city: 'KLANG', state: 'SELANGOR'},
  // {site: 'WAN HING AUTOMOTIVE', city: 'BANDAR BARU BANGI', state: 'SELANGOR'},
  //{site: 'LIM TAYAR ALAM DAMAI', city: 'CHERAS', state: 'KUALA LUMPUR'},
  //{site: 'SHELL KESAS R&R AWAN BESAR', city: 'KUALA LUMPUR', state: 'SELANGOR'},
  //{site: 'SHELL NSE BUKIT JALIL (TPM 2)', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL KESAS R&R KINRARA', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL BANDAR TASEK SELATAN', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL BANDAR SRI MENJALARA', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'SHELL BUKIT CHERAS', city: 'KUALA LUMPUR', state: 'KUALA LUMPUR'},
  //{site: 'PUTRA SQUARE KUANTAN', city: 'KUANTAN', state: 'PAHANG'},
  //{site: 'KTCC MALL', city: 'KUALA TERENGGANU', state: 'TERENGGANU'},
  //{site: 'SHELL JLN BUKIT SETONGKOL KUANTAN', city: 'KUANTAN', state: 'PAHANG'},
  //{site: 'SHELL JLN GAMBANG KUANTAN KM18', city: 'KUANTAN', state: 'PAHANG'},
  //{site: 'G SPOT AUTOCARE', city: 'KLANG UTAMA', state: 'SELANGOR'},
  //{site: 'GLOBAL AUTO CENTRE', city: 'TEMERLOH', state: 'PAHANG'},
  //{site: 'SHELL FED HIGHWAY SHAH ALAM BATU3', city: 'SUBANG', state: 'SELANGOR'},
  //{site: 'SHELL PUCHONG PERMAI', city: 'PUCHONG', state: 'SELANGOR'},
  //{site: 'SHELL JALAN RAWANG', city: 'RAWANG', state: 'SELANGOR'},
];

@Component({
  selector: 'app-fitment-centres',
  templateUrl: './fitment-centres.component.html',
  styleUrls: ['./fitment-centres.component.scss']
})
export class FitmentCentresComponent implements OnInit {

  displayedColumns: string[] = ['site', 'city', 'state'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  fitmentCentres: string = environment.fitmentCentres;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() { }

  ngOnInit() {
    window.location.href = this.fitmentCentres;
    this.dataSource.sort = this.sort;
  }

}
