import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/api-service/authentication.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/api-service/user.service';
import { RfidService } from 'src/app/api-service/rfid.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ShopeeRedirectModalComponent } from 'src/app/content-pages/shared-module/shopee-redirect-modal/shopee-redirect-modal.component';

export let browserRefresh = false;

@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit, OnDestroy {

  @Input('headerLayout') layout = 'hide';

  getNameSub: Subscription;
  postLogoutSub: Subscription;

  name: string;
  currURL: string;
  isLoading: boolean = false;
  isSideNavOpen: boolean = false;
  isPreLoginMobileMenuOpen: boolean = false;
  isPreLoginProfileMenuOpen: boolean = false;
  isPilot: boolean = false;
  isOpen: boolean = true;
  isClose: boolean = false;
  // hasLogin: boolean = false;

  subitemsIcon: any;
  subitemsIconDisplay: any;

  constructor(private router: Router, private route: ActivatedRoute,
    private eleRef: ElementRef, private authService: AuthenticationService, private userService: UserService, private location: Location, private rfidService: RfidService, private dialog: MatDialog) {
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationStart) {
        this.isPreLoginMobileMenuOpen = false;
        this.isSideNavOpen = false;
        this.isPreLoginProfileMenuOpen = false;

        var subitemsList = this.eleRef.nativeElement.querySelectorAll('.l-side-bar-menu-subitems active--');
        for (let i = 0; i < subitemsList.length; i++) {
          subitemsList[i].classList.remove("active--");
        }
      }

      if (data instanceof NavigationEnd) {
        this.currURL = data.url;

        // const hasLogin: boolean = (this.authService.currentTokenValue != null);
        // console.log("hasLogin: ", hasLogin)
        // if (hasLogin) {
        //   this.getNameSub = userService.currUserProfile.subscribe(resp => {
        //     if (resp != null) {
        //       if (resp.fullName)
        //         this.name = resp.fullName;
        //       else
        //         this.name = "User";
        //     }
        //   });
        // }
        // else
        //   this.name = null;
      }
    });

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        setTimeout(() => { this.scrollToID(fragment) }, 0);
      }
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    // this.rfidService._isPilot.subscribe((value: any) => {
    //   this.isPilot = value;
    // })

    this.authService.currAccessToken.subscribe(value => {
      if (value != null) {
        this.getNameSub = this.userService.currUserProfile.subscribe(resp => {
          if (resp != null) {
            this.isPilot = resp.isPilot;

            if (resp.fullName)
              this.name = resp.fullName;
            else
              this.name = "User";
          }
        });
      } else {
        this.name = null;
      }
    })
  }

  ngOnDestroy() {
    if (this.getNameSub)
      this.getNameSub.unsubscribe();

    if (this.postLogoutSub)
      this.postLogoutSub.unsubscribe();
  }

  openPreLoginMenu() {
    this.isPreLoginMobileMenuOpen = true;
  }

  closePreLoginMenu() {
    this.isPreLoginMobileMenuOpen = false;
  }

  openSideNavMenu() {
    this.isSideNavOpen = true;
    this.isOpen = false;
    this.isClose = true;
  }

  closeSideNavMenu() {
    this.isSideNavOpen = false;
    this.isOpen = true;
    this.isClose = false;
  }

  checkURLIncludes(str: string) {
    if (this.router.url.includes(str)) {
      return true;
    }
    return false;
  }

  toggleSubItems(e: Event) {
    var target = e.currentTarget as Element;
    var subitems = target.nextElementSibling;
    var subitemsList = this.eleRef.nativeElement.querySelectorAll('.l-side-bar-menu-subitems');
    for (let i = 0; i < subitemsList.length; i++) {
      subitemsList[i].classList.remove("active--");
    }
    subitems.classList.add("active--");


    var subitemsIcon = target.children;
    var subitemsIconDisplay = this.eleRef.nativeElement.querySelectorAll('.l-side-bar-menu-item__icon');
    for (let i = 0; i < subitemsIconDisplay.length; i++) {
      subitemsIconDisplay[i].classList.remove("active--");
    }
    subitemsIcon[1].classList.add("active--");
    // console.log(subitemsIconDisplay);
  }

  backRoute() {
    switch (this.currURL) {
      // case '/payment': this.router.navigate(['/rfid-tag-purchase']);
      case '/payment': this.dialog.open(ShopeeRedirectModalComponent, {
        panelClass: 'shopee-redirect-modal'
      });
        break;
      // case '/rfid-tag-purchase': this.router.navigate(['/buy-rfid-tag']);
      case environment.shopeeRFIDTagPurchase: this.router.navigate(['/buy-rfid-tag']);
        break;
      default:
        this.location.back();
        break;
    }
  }

  getFirstletter(str: string): string {
    return str.charAt(0);
  }

  logout() {
    this.isLoading = true;
    this.postLogoutSub = this.authService.logout().subscribe(
      (resp) => {
        this.router.navigate(['/login']);
      },
      (err) => {
        const msg: string = "Failed to logout.";
        console.error(msg + "\nERROR: " + err);
        //this.errMsgs.push(msg);
        this.isLoading = false;
      });
  }

  scrollToID(targetID: string) {
    var target = document.querySelector('#' + targetID) as HTMLElement;
    if (target) {
      window.scrollTo({
        top: (target.offsetTop - 100),
        behavior: "smooth"
      });
    }
  }

}
