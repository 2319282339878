import { Directive, AfterViewInit, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
	selector: '[tooltipCustomAction]'
})
export class TooltipCustomActionDirective implements AfterViewInit {

	constructor(private eleRef:ElementRef) { 
	}

	@Input('tooltipCustomAction') tooltipTarget;
	tooltipIsOpen:boolean = false;
	eventLock:boolean = false; 

	isMouseEnter:boolean = false;
	isMobile:boolean = false;

	ngAfterViewInit(){
		this.eleRef.nativeElement.classList.add('tooltip-custom')
	}

	@HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    	this.tooltipIsOpen = false;
	  	this.tooltipController();
    }

	@HostListener('click') onMouseDown() {
		this.tooltipIsOpen = !this.tooltipIsOpen;
		this.tooltipController();
  	}

  	@HostListener('touchstart') ontouchStart() {
  		this.isMobile = true;
  	}

  	@HostListener('mouseenter') onMouseEnter() {
  		if(!this.isMobile){
	  		this.tooltipIsOpen = true;
	  		this.tooltipController();
		}
  	}

  	@HostListener('mouseleave') onMouseLeave() {
  		this.tooltipIsOpen = false;
	  	this.tooltipController();
  	}

  	tooltipController(){
		if(this.tooltipIsOpen){
			this.tooltipTarget.show();
			this.eleRef.nativeElement.innerText = "info";
		}else{
			this.tooltipTarget.hide();
			this.eleRef.nativeElement.innerText = "info_outline";
		}
  	}

}
