import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MasterdataService } from 'src/app/api-service/masterdata.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { RefundStatusPipe } from 'src/app/content-pages/shared-module/custom-pipes/refund-status.pipe';

import * as _moment from 'moment';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'refund-history-modal',
  templateUrl: './refund-history-modal.component.html',
  styleUrls: ['./refund-history-modal.component.scss'],
  providers:[RefundStatusPipe]
})
export class RefundHistoryModalComponent implements OnInit {
  @ViewChild('refundHistoryModal', { static: false }) refundHistoryModal: TemplateRef<any>;
  @Input('refundData') refundData: any;

  refundDetails: any;

  constructor(
    private dialog: MatDialog,
    private statusPipe: RefundStatusPipe
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes) {
      this.refundDetails = this.refundData;
    }
  }

  openModal() {
    this.dialog.open(this.refundHistoryModal, {
      width: '965px',
      minWidth: '349px'
    });
  }

  modalClose() {
    this.dialog.closeAll();
  }

  refundStatusClass(status: string) {
    let refundStatus = this.statusPipe.transform(status);

    switch(refundStatus) {
      case 'Completed': {
        return 'completed--';
      }

      case 'Rejected' : {
        return 'rejected--'
      }

      default : {
        return 'pending--';
      }
    }
  }

  checkTrxUsage(data: any) {
    if(data === 'B') {
      return 'RFID Fare Usage <br> - Toll (Barrier)';
    }
  }
}
