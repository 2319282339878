import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../api-service/authentication.service';
import { UserService } from '../../../api-service/user.service';

@Component({
  selector: 'title-status',
  templateUrl: './title-status.component.html',
  styleUrls: ['./title-status.component.scss']
})
export class TitleStatusComponent implements OnInit, OnDestroy {

  @Input('isMobile') isMobile;

  getNameSub: Subscription;
  postLogoutSub: Subscription;

  isLoading: boolean = false;
  name: string = '';

  constructor(private router: Router, private authService: AuthenticationService, private userService: UserService) {
    this.getNameSub = userService.currUserProfile.subscribe(resp => {
      if (resp != null) {
        if (resp.fullName)
          this.name = resp.fullName;
        else
          this.name = "User";
      }
    });
  }

  ngOnInit() {
  }

  logout() {
    this.isLoading = true;
    this.postLogoutSub = this.authService.logout().subscribe(
      (resp) => {
        this.router.navigate(['/login']);
      },
      (err) => {
        const msg: string = "Failed to logout.";
        console.error(msg + "\nERROR: " + err);
        //this.errMsgs.push(msg);
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    if (this.getNameSub)
      this.getNameSub.unsubscribe();

    if (this.postLogoutSub)
      this.postLogoutSub.unsubscribe();
  }

}
