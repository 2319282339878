import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { eVehicleStatus, eTagStatus, eBindTagProcess, eBindTagReplacementProcess } from 'src/app/helpers/enums';
import { Vehicle } from '../../../models/vehicle';


@Component({
  selector: 'vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss']
})
export class VehicleCardComponent implements OnInit, OnChanges {

  @Input() vehicleData: Vehicle;
  @Output() delVehicleId: EventEmitter<string> = new EventEmitter<string>();
  @Output() addTag: EventEmitter<string> = new EventEmitter<string>();

  isEditable: boolean = false;
  isDeletable: boolean = false;
  isViewable: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    const chg: SimpleChange = changes.vehicleData;
    if (chg) {
      let vhStatus = this.vehicleData.vehicleStatus;

      this.checkIsEditable(vhStatus);

      this.isDeletable = (vhStatus === eVehicleStatus.PendingInfo || vhStatus === eVehicleStatus.PendingConfirmation || vhStatus === eVehicleStatus.PendingSubmit);
      this.isViewable = (vhStatus == eVehicleStatus.Active || this.vehicleData.tagStatus == eTagStatus.Suspended);
    }
  }

  get bindTagReplacementProcess() { return eBindTagReplacementProcess; }

  getCustomClass(vhStatus: string, tngStatus: string): string {
    let cardStyle: string = (vhStatus == eVehicleStatus.Active ||
      ((vhStatus == eVehicleStatus.PendingSuspension || vhStatus == eVehicleStatus.PendingTermination) && tngStatus == eTagStatus.Active)
      ? "active--" : "pending--");

    if (vhStatus != eVehicleStatus.PendingActivation)
      cardStyle += " click--";

    if (tngStatus === eTagStatus.Suspended || tngStatus === eTagStatus.Terminated)
      cardStyle += " inactive--"

    return cardStyle;
  }

  getStatusClass(vhStatus: string, tngStatus: string): string {
    let statusStyle = "";
    if (tngStatus === eTagStatus.Suspended) {
      return 'suspended--';
    } else if (tngStatus === eTagStatus.Terminated) {
      return 'terminated--';
    } else if (vhStatus === eVehicleStatus.Active ||
      ((vhStatus == eVehicleStatus.PendingSuspension || vhStatus == eVehicleStatus.PendingTermination) && tngStatus == eTagStatus.Active)) {
      return 'active--';
    } else if (vhStatus === eVehicleStatus.PendingActivation) {
      return 'pd-active--';
    } else if (vhStatus === eVehicleStatus.PendingInfo ||
      vhStatus === eVehicleStatus.PendingConfirmation ||
      vhStatus === eVehicleStatus.PendingSubmit) {
      return 'pd-submit--';
    }

    return statusStyle;
  }

  getVehicleStatus(vhStatus: string, tagStatus: string): string {
    let status: string = null;

    switch (vhStatus) {
      case eVehicleStatus.PendingInfo:
      case eVehicleStatus.PendingConfirmation:
      case eVehicleStatus.PendingSubmit:
        status = eVehicleStatus.PendingSubmit;
        break;
      case eVehicleStatus.PendingActivation:
        status = vhStatus;
        break;
      case eVehicleStatus.PendingSuspension:
      case eVehicleStatus.PendingTermination:
        status = tagStatus;
      default:
        status = tagStatus;
        break;
    }

    if (status && status != null)
      return status.replace(new RegExp('_', 'g'), " ");
    else
      return null;
  }

  checkIsEditable(vhStatus: string) {
    switch (vhStatus) {
      case eVehicleStatus.Active:
      case eVehicleStatus.PendingActivation:
      case eVehicleStatus.PendingSuspension:
      case eVehicleStatus.PendingTermination:
        this.isEditable = false;
        break;
      default:
        this.isEditable = true;
        break;
    }
  }

  viewDetail() {
    const vhStatus: string = this.vehicleData.vehicleStatus;

    if (vhStatus == eVehicleStatus.PendingActivation)
      return;

    switch (vhStatus) {
      case eVehicleStatus.Active:
      case eVehicleStatus.PendingSuspension:
      case eVehicleStatus.PendingTermination:
        this.router.navigate(["/my-vehicle/detail", this.vehicleData.id]);
        break;
      case eVehicleStatus.PendingInfo:
      case eVehicleStatus.PendingConfirmation:
      case eVehicleStatus.PendingSubmit:
        this.addTag.emit(eBindTagProcess.ActivateTag);
        break;
    }
  }

  delVehicle() {
    this.delVehicleId.emit(this.vehicleData.id);
  }
}
