import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'refundPaymentMethod' })
export class RefundPaymentMethodPipe implements PipeTransform {
  transform(value: any) {
    switch(value) {
      case '01':  {
        return 'Telegraphic Transfer'
      }

      case '02':  {
        return 'Bank Account'
      }

      case '03' : {
        return "Touch 'n Go eWallet";
      }

      case '04' : {
        return 'Duit Now'
      }

      case '05' : {
        return 'Credit Card / Debit Card'
      }

      case '06' : {
        return 'Cheque'
      }

    }
  }
}
