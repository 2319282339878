import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'refundStatus' })
export class RefundStatusPipe implements PipeTransform {
  transform(value: any) {
    switch(value) {
      case 'New':
      case 'Verified' :
      case 'SP Approved':
      case 'Pre-approved':
      case 'Direct Approved':
      case 'Refund Approved':
      case 'Refund Successful':  {
        return 'In Progress';
      }

      case 'Rejected':
      case 'SP Rejected':
      case 'Refund Rejected':
      case 'Refund Unsuccessful': {
        return 'Rejected'
      }

      case 'Closed' : {
        return 'Completed'
      }
    }
  }
}
