import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageKey } from '../helpers/enums';
import { ChangeEmailRequest, ChangeEmailResponse, ChangeWalletRequest, ChangeWalletResponse, SimpleUser, UpdateUserResponse, UserRequest, UserResponse } from '../models/user';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currUserProfileSubject: BehaviorSubject<SimpleUser>;
  public currUserProfile: Observable<SimpleUser>;

  constructor(private httpClient: HttpClient) {
    this.currUserProfileSubject = new BehaviorSubject<SimpleUser>(JSON.parse(localStorage.getItem(StorageKey.CurrUserProfile)));
    this.currUserProfile = this.currUserProfileSubject.asObservable();
  }

  public get currentUserProfileValue(): SimpleUser {
    return this.currUserProfileSubject.value;
  }

  //  View Customer Profile
  public getProfile(): Observable<UserResponse> {
    if (internalTest) {
      let apiURL: string;
      let email: string = localStorage.getItem(StorageKey.LoginEmail);
      switch (email) {
        case "new@new.com": apiURL = 'assets/json/user/user-new.json';
          break;
        case "tng@tng.com": apiURL = 'assets/json/user/user-tng.json';
          break;
        default: apiURL = 'assets/json/user/user-old.json';
          break;
      }

      return this.httpClient.get<UserResponse>(apiURL)
        .pipe(map(userProfile => {
          let user: SimpleUser = new SimpleUser(userProfile);

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(user));
          this.currUserProfileSubject.next(user);

          return userProfile;
        }));
    }
    else {
      return this.httpClient.get<UserResponse>(`${apiURL}me`)
        .pipe(map(userProfile => {
          if (!environment.IRS_whiteList) {
            // force ispilot to true if irs whitelist is false
            userProfile.isPilot = true;
          }

          let user: SimpleUser = new SimpleUser(userProfile);

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(user));
          this.currUserProfileSubject.next(user);

          return userProfile;
        }));
    }
  }

  //  Update Profile
  public updateProfile(updateUserRequest: UserRequest): Observable<UpdateUserResponse> {
    if (internalTest) {
      return this.httpClient.get<UpdateUserResponse>('assets/json/updateuser.json', { headers: header })
        .pipe(map(userProfile => {
          let user: SimpleUser = new SimpleUser(userProfile.customer);

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(user));
          this.currUserProfileSubject.next(user);

          return userProfile;
        }));
    }
    else {
      return this.httpClient.put<UpdateUserResponse>(`${apiURL}me`, updateUserRequest, { headers: header })
        .pipe(map(userProfile => {
          let user: SimpleUser = new SimpleUser(userProfile.customer);

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(user));
          this.currUserProfileSubject.next(user);

          return userProfile;
        }));
    }
  }

  //  Change Email
  public changeEmail(changeEmailRequest: ChangeEmailRequest): Observable<ChangeEmailResponse> {
    if (internalTest) {
      return this.httpClient.get<ChangeEmailResponse>('assets/json/baseresponse.json', { headers: header })
        .pipe(map(resp => {
          var up = this.currentUserProfileValue;
          up.email = changeEmailRequest.newEmail;

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(up));
          this.currUserProfileSubject.next(up);

          return resp;
        }));
    }
    else {
      return this.httpClient.put<ChangeEmailResponse>(`${apiURL}me/change-email`, changeEmailRequest, { headers: header })
        .pipe(map(resp => {
          var up = this.currentUserProfileValue;
          up.email = changeEmailRequest.newEmail;

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(up));
          this.currUserProfileSubject.next(up);

          return resp;
        }));
    }
  }

  // Change E-wallet ID
  public changeEwalletID(changeWalletRequest: ChangeWalletRequest): Observable<ChangeWalletResponse> {
    if (internalTest) {
      return this.httpClient.get<ChangeWalletResponse>('assets/json/baseresponse.json', { headers: header })
        .pipe(map(resp => {
          var up = this.currentUserProfileValue;
          up.mobileCountryCode = changeWalletRequest.newMobileCountryCode;
          up.mobileNo = changeWalletRequest.newMobileNo;

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(up));
          this.currUserProfileSubject.next(up);

          return resp;
        }));
    }
    else {
      return this.httpClient.post<ChangeWalletResponse>(`${apiURL}me/change-mobile-phone`, changeWalletRequest, { headers: header })
        .pipe(map(resp => {
          var up = this.currentUserProfileValue;
          up.mobileCountryCode = changeWalletRequest.newMobileCountryCode;
          up.mobileNo = changeWalletRequest.newMobileNo;

          localStorage.setItem(StorageKey.CurrUserProfile, JSON.stringify(up));
          this.currUserProfileSubject.next(up);

          return resp;
        }));
    }
  }
}
