import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/country';
import { Gender } from '../models/gender';
import { IDType } from '../models/idtype';
import { Nationality } from '../models/nationality';
import { State } from '../models/state';
import { TagRequestReason } from '../models/tagrequestreason';
import { TagRequestType } from '../models/tagrequesttype';
import { VehicleMake } from '../models/vehiclemake';
import { VehicleType } from '../models/vehicletype';
import { CountryCode } from '../models/countrycode';
import { UserCount } from '../models/rfiduser';
import { SortCriteria } from '../models/sortcriteria';
import { Toll } from '../models/plazatoll';
import { BusinessSectorModel } from '../models/business-sector';
import { CityRequest, City } from '../models/city';
import { AddressProfileListResponse, AddressProfileResponse } from '../models/addressprofile';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const minYear: number = environment.minYear;
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;
// const apiURL2: string = environment.apiEndpoint2;

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {

  constructor(private httpClient: HttpClient) { }

  public getYears(): number[] {
    const currYear: number = new Date().getFullYear();
    const totalYear: number = (currYear - minYear) + 1;
    return Array.from({ length: totalYear }, (x, y) => minYear + y);
  }

  public getNumberOfCustomer(): Observable<UserCount> {
    const url: string = internalTest ? 'assets/json/numberofcustomer.json' : `${apiURL}number-of-customers`;

    return this.httpClient.get<UserCount>(url, { headers: header });
  }

  public getGender(): Observable<Gender[]> {
    const url = 'assets/json/gender.json';

    return this.httpClient.get<Gender[]>(url, { headers: header });
  }

  public getIDType(): Observable<IDType[]> {
    const url: string = internalTest ? 'assets/json/idtype.json' : `${apiURL}data/id-types`;

    return this.httpClient.get<IDType[]>(url, { headers: header });
  }

  public getCountryCode(): Observable<CountryCode[]> {
    const url: string = internalTest ? 'assets/json/countrycode.json' : `${apiURL}data/mobile-country-codes`;

    return this.httpClient.get<CountryCode[]>(url, { headers: header });
  }

  public getNationality(): Observable<Nationality[]> {
    const url: string = internalTest ? 'assets/json/nationality.json' : `${apiURL}data/nationalities`;

    return this.httpClient.get<Nationality[]>(url, { headers: header });
  }

  public getCountry(): Observable<Country[]> {
    const url: string = internalTest ? 'assets/json/country.json' : `${apiURL}data/countries`;

    return this.httpClient.get<Country[]>(url, { headers: header });
  }

  public getState(): Observable<State[]> {
    const url: string = internalTest ? 'assets/json/state.json' : `${apiURL}data/states-data`;

    return this.httpClient.get<State[]>(url, { headers: header });
  }

  public getCity(cityReq: CityRequest): Observable<City[]> {
    if (internalTest) {
      let city: string = 'kl-city.json';
      switch (cityReq.state) {
        case 'SL': city = 'slg-city.json';
          break;
        case 'ML': city = 'mlk-city.json';
          break;
        default: city = 'kl-city.json';
          break;
      }
      return this.httpClient.get<City[]>(`assets/json/location/${city}`, { headers: header });
    }
    else
      return this.httpClient.post<City[]>(`${apiURL}data/cities`, cityReq, { headers: header });
  }

  // Get List of Address Profiles
  public getAddressProfileList(): Observable<AddressProfileListResponse[]> {
    const url: string = internalTest ? 'assets/json/addressprofilelist.json' : `${apiURL}me/address-profiles`;

    return this.httpClient.get<AddressProfileListResponse[]>(url, { headers: header });
  }

  // Get Address Profile
  public getAddressProfile(addressProfileId: string): Observable<AddressProfileResponse> {
    const url: string = internalTest ? 'assets/json/addressprofile.json' : `${apiURL}address-profiles/${addressProfileId}`;

    return this.httpClient.get<AddressProfileResponse>(url, { headers: header });
  }

  public getVehicleType(): Observable<VehicleType[]> {
    const url: string = internalTest ? 'assets/json/vehicletype.json' : `${apiURL}data/vehicle-types`;

    return this.httpClient.get<VehicleType[]>(url, { headers: header });
  }

  public getVehicleMakeNModels(): Observable<VehicleMake[]> {
    const url: string = internalTest ? 'assets/json/vehiclemake.json' : `${apiURL}data/vehicle-makes-models`;

    return this.httpClient.get<VehicleMake[]>(url, { headers: header });
  }


  //  Get Tag Request Types
  public getTagRequestType(): Observable<TagRequestType[]> {
    const url: string = internalTest ? 'assets/json/tagrequesttype.json' : `${apiURL}data/tag-request-actions`;

    return this.httpClient.get<TagRequestType[]>(url, { headers: header });
  }

  //  Get Tag Request Reason Codes
  public getTagRequestReason(): Observable<TagRequestReason[]> {
    const url: string = internalTest ? 'assets/json/tagrequestreason.json' : `${apiURL}master-data/tag-request-reasons`;

    return this.httpClient.get<TagRequestReason[]>(url, { headers: header });
  }

  //  Get List of Business Sectors
  public getBusinessSector(): Observable<BusinessSectorModel[]> {
    const url: string = internalTest ? 'assets/json/businesssectors.json' : `${apiURL}data/business-sectors`;

    return this.httpClient.get<BusinessSectorModel[]>(url, { headers: header });
  }

  //  Get List of Sort Criteria
  public getSortCriteria(): Observable<SortCriteria[]> {
    const url: string = internalTest ? 'assets/json/sortcriteria.json' : `${apiURL}data/sort-criteria`;

    return this.httpClient.get<SortCriteria[]>(url, { headers: header });
  }

  //  Get List of Plaza for Highway
  public getPlazaToll(): Observable<Toll[]> {
    return this.httpClient.get<Toll[]>('assets/json/transaction/plaza-toll.json', { headers: header });
  }

  public getTollPlaza() { 
    return this.httpClient.get(`${apiURL}data/plaza`).toPromise()
  }
}
