export enum eWalletStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Close = 'CLOSED'
}

export enum eTagStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

export enum eIDTypes {
  MyKad = "1",
  Passport = "2",
  Others = "5"
}

export enum eRequestStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED'
}

export enum eVehicleStatus {
  PendingInfo = 'PENDING_VEHICLE_INFO',
  PendingConfirmation = 'PENDING_CONFIRMATION',
  PendingSubmit = 'PENDING_SUBMISSION',
  PendingActivation = 'PENDING_ACTIVATION',
  PendingSuspension = 'PENDING_SUSPENSION',
  PendingTermination = 'PENDING_TERMINATION',
  Active = 'ACTIVE'
}

export enum eSortDirection {
  ascending = 'asc',
  descending = 'desc'
}

export enum eSortFieldName {
  postedDate = 'postedDateTime',
  vehicleNo = 'vehiclePlateNumber',
  businessSector = 'businessSectorName',
  trxType = 'transactionTypeName',
  trxStatus = 'transactionStatus',
  amount = 'amount',
  entryLocName = 'entryLocationName',
  exitLocName = 'exitLocationName',
  tag_reqId = 'id',
  tag_vehicleNo = 'vehicleNumber',
  tag_reqType = 'requestType',
  tag_reqDate = 'requestDate',
  tag_reqStatus = 'requestStatus',
  tag_reqRemark = 'requestRemarks',
  order_createdDate = 'orderCreatedDate'
}

export enum eBindTagProcess {
  CheckPoint = "dots",
  ActivateTag = "tagActivation",
  AddUser = "addUserInfo",
  VerifyVehicle = "addVehicle",
  AddVehicle = "addVehicleDetails",
  VehicleNotFound = "couldNotFindVehicle",
  VehicleExist = "vehicleRegistered",
  //ConfirmVehicle = "confirmVehicle",
  //HaveTngWallet = "haveTnGWallet",
  LoginSOF = "paymentMethod",
  ConfirmSOF = "confirmPaymentMethod",
  ConfirmDetails = "confirmAllDetails",
  Complete = "done"
}

export enum eBindTagReplacementProcess {
  CheckPoint = "dots",
  ActivateTag = "tagActivation",
  ConfirmDetails = "confirmAllDetails",
  Complete = "done"
}

export enum eEditShippingProcess {
  SelectAddress = "selectAddress",
  AddAddress = "addAddress"
}

export enum RespStatus {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  InvalidToken = 402,
  NotFound = 404,
  ServerError = 500
}

export enum StatusCode {
  Success = "000",
  RequiredFieldOrParameterMissing = "100",
  InvalidValueOrWrongFormat = "101",
  DuplicatedID = "102",
  IncorrectValue = "103",
  IDNotFound = "104",
  ParameterDoesNotMeetRequirement = "105",
  UnsatisfiedDependency = "106",
  InactiveSOF = "107",
  InsufficientBalance = "111",
  MissingToken = "200",
  TokenExpired = "201"
}

export enum VerifySignUpStatusCode {
  // Successful response
  NewCustomer = "000",
  PreMigrated = "001",
  AccountPendingActivation = "002",

  // Error response
  InvalidEmailAddress = "101",
  EmailAreadyExist = "102"
}

export enum SignUpStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  EmailAreadyExist = "102",
  NoConsent = "103",
  IncorrectRecaptcha = "104"
}

export enum ResendActivationEmailStatusCode {
  // Successful response
  Success = "000",

  // Error response
  EmailIsEmpty = "100",
  InvalidEmailAddress = "101",
  EmailAreadyActivated = "102",
  EmailIsNotRegister = "103"
}

export enum AccountActivationStatusCode {
  // Successful response
  Success = "000",

  // Error response
  MissingToken = "401",
  ExpiredTokenOrInvalidToken = "402"
}

export enum MfaStatusCode {
  // Successful response
  Success = "000",

  // Error response
  AccountStillLocked = "103",
  ExpiredOrInvalidCode = "008"
}

export enum ForgotPasswordStatusCode {
  // Successful response
  Success = "000",

  // Error response
  EmailIsEmpty = "100",
  InvalidEmailAddress = "101",
  EmailIsNotRegister = "102",
  AccountNotVerify = "103"
}

export enum ForgotPasswordVerificationStatusCode {
  // Successful response
  Success = "000",

  // Error response
  MissingToken = "401",
  ExpiredTokenOrInvalidToken = "402"
}

export enum ResetPasswordStatusCode {
  // Successful response
  Success = "000",

  // Error response
  PasswordIsEmpty = "100",
  NewPasswordNotMeetPolicy = "101",
  MissingToken = "401",
  ExpiredTokenOrInvalidToken = "402"
}

export enum LoginStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidEmailAddress = "101",
  AccountPendingActivation = "102",
  EmailIsNotRegister = "104",
  IncorrectPassword = "105"
}

export enum ChangePasswordStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  IncorrectOldPassword = "103",
  NewPasswordNotMeetPolicy = "104"
}

export enum ChangeEmailStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidEmailAddress = "101",
  NewEmailInUse = "102",
  IncorrectPassword = "103",
  EmailIsNotRegister = "104",
  AccountPendingActivation = "105"
}

export enum ChangeSOFStatusCode {
  Success = "000",
  ParameterIsEmpty = "100",
  InvalidMobileNo = "101",
  IncorrectOldMobileNo = "102",
  IncorrectPin = "103",
  MismatchSOFId = "104"
}

export enum UpdateProfileStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101"
}

export enum UpdateVehicleStatusCode {
  // Successful response
  Success = "200",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  VehiclePlateNumberInUse = "300",
  VehiclePlateNumberInAnotherRequest = "301",
  VehicleHasBeenVerified = "302"
}

export enum DeleteVehicleStatusCode {
  // Successful response
  Success = "200",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  VehicleHasATag = "300"
}

export enum ValidateRFIDStatusCode {
  // Successful response
  Success = "000",

  // Error response
  TagIDIsEmpty = "100",
  InvalidTagID = "101",
  TagIsAlreadyInUse = "102"
}

export enum TagActivationStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidTagID = "101",
  TagIsAlreadyInUse = "102"
}

export enum TagReplacementActivationStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidTagID = "101",
  VehicleNotFound = "103",
  VehicleHasATag = "104",
  VehicleDoesNotHaveATagReplacement = "105",
  TagIDIsInUseByAnotherVehicle = "106",
  MissMatchTagID = "107"
}

export enum VerifySOFStatusCode {
  // Successful response
  Success = "200",

  // Error response
  IncorrectPin = "103",
  MobilePhoneNumberNotRegister = "104",
  InactiveEWallet = "200",
  InsufficientBalance = "201"
}

export enum BindSOFStatusCode {
  // Successful response
  Success = "200",

  // Error response
  PinIsEmpty = "100",
  IncorrectPin = "101",
  MobilePhoneNumberNotRegister = "102"
}

export enum BindSOFToMxAccountStatusCode {
  // Successful response
  Success = "200",

  // Error response
  DuplicateAccount = "202",
  NoVehicleActivated = "144"
}

export enum ValidateVehicleStatusCode {
  // Successful response
  Success = "000",

  // Error response
  VehiclePlateNumberIsEmpty = "100",
  InvalidVehiclePlateNumber = "101",
  VehicleInfoNotFound = "300",
  VehiclePlateNumberInUse = "301",
  VehiclePlateNumberInAnotherRequest = "302"
}

export enum BindVehicleTagStatusCode {
  // Successful response
  Success = "000",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  VehicleAlreadyRegistered = "103",
  VehicleHasATag = "104",
  TagIDInUse = "105"
}

export enum SuspendTerminateTagStatusCode {
  // Successful response
  Success = "200",

  // Error response
  ParameterIsEmpty = "100",
  InvalidParameter = "101",
  TagIDInProgress = "102",
  TagIDNotFound = "103",
  InactiveAccount = "104"
}

export enum DeleteOrderStatusCode {
  // Successful response
  Success = "000",

  // Error response
  InvalidOrderReqID = "100",
  OrderCannotBeDeleted  = "101"
}

export enum StorageKey {
  CurrUserProfile = "up",
  AccessToken = "ac",
  RefreshToken = "sh",
  HasTempPassword = "tp",
  LoginEmail = "le",
  TempPassword = "tps",
  MfaRequested = "mr",
  MfaVerified = "mv",
  OtpAttempt = "oa",
  MaxOtpAttempt = "mo"
}

export enum SessionStorageKey {
  OrderId = "oid"
}

export enum StatusVerification {
  AccVerification_Success = 1,
  AccVerification_Fail = 2,
  ResetPassword_Success = 3,
  ResetPassword_Fail = 4,
  ChangeEmailVerification_Success = 5,
  ChangeEmailVerification_Fail = 6,
  Payment_Success = 7,
  Payment_Processing = 8,
  Payment_Fail = 9,
  Payment_Cancel = 10,
  Processing_Error = 11
}

export enum eTagRequestType {
  Suspension = "SUSPENSION",
  Unsuspend = "UNSUSPEND",
  Termination = "TERMINATION",
  Replacement = "REPLACEMENT"
}

export enum ModalAction {
  Cancel = 0,
  Proceed = 1
}

export enum eRefundReason {
  DoubleDeduct = "Double Deduction",
  Penalty = "Penalty",
  IncorrectFare = "Incorrect Fare",
  Others = "Others"
}

export enum eTrxStatus {
  Success = "SUCCESS",
  Pending = "PENDING"
}

export enum eRefundTrxStatus {
  Successful = "SUCCESSFUL",
  Unsuccessful = "UNSUCCESSFUL",
  InProgress = "IN-PROGRESS"
}

export enum eTrxType {
  Usage = "Fare Usage",
  Reload = "Reload",
  CancelUsage = "Usage Cancellation",
  CancelReload = "Reload Cancellation"
}

export enum eCMSContentStatus {
  Published = "published",
  Draft = "draft",
  Archived = "archive"
}

export enum SetPasswordSuccessButtonText {
  Dashboard = "Proceed To Dashboard",
  TagPurchase = "Proceed To RFID Tag Purchase"
}

export enum eOrderStatus {
  All = "",
  Draft = "DRAFT",
  Submitted = "SUBMITTED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED"
}

export enum eOrderStatusGroup {
  All = "",
  Draft = "DRAFT",
  Paid = "PAID",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  Cancelled = "CANCELLED"
}

export enum OrderCancellationReasonCode {
  Others = '01',
  ModifyExistingOrder = '02'
}

export enum ePaymentStatus {
  All = "",
  Success = "SUCCESS",
  Processing = "PROCESSING",
  Fail = "FAILED",
  Cancel = "CANCELLED"
}

export enum eConsignmentStatus {
  All = "",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  DeliverRefused = "REFUSE DELIVERY",
  Replaced = "REPLACED"
}

export enum eOrderRequestType {
  Cancel = "CANCELLATION"
}

export enum eOrderDateFilter {
  LastYear = 365,
  Last6Months = 180,
  Last3Months = 90,
  LastMonth = 30,
  LastWeek = 7
}

export enum ePaymentMethod {
  TNGWallet = '01',
  CreditCard = '02'
}

export enum ePaymentCard {
  Visa = "VISA",
  Master = "MASTER"
}

export enum ePaymentTrxType {
  Sales = '01'
}

export enum eAddressType {
  Shipping = "SHIPPING",
  Billing = "BILLING"
}

export enum eOrderProgressClass {
  Draft = 'draft--',
  Paid = 'paid--',
  Shipped = 'shipped--',
  Delivered = 'delivered--',
  Cancelled = 'cancelled--'
}

export enum eFuelBayStatus {
  SystemMaintenance = '10',
  UnderRenovation = '11',
  ChangedMerchant = '12',
  PendingOrt = '13',
  Online = '20'
}
