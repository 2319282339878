// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: '/mx/api/v1/',
  apiEndpoint2: '/mx-dev/mx/api/v1/', 
  cmsEndpoint: 'http://47.254.196.25/',
  isInternalTest: false,   // warning: set to false for production deployment
  IRS_whiteList: false, // Set to false for public launching

  // Reference URL
  whistleblowingPolicy: 'https://www.touchngo.com.my/policies/whistleblowing-policy',
  antiBriCorrPolicy: 'https://www.touchngo.com.my/policies/anti-bribery-and-corruption-policy',
  termsConditions: 'https://www.touchngo.com.my/policies/terms-conditions',
  privacyPolicy: 'https://www.touchngo.com.my/policies/privacy-policy',
  custServiceCharter: 'https://www.touchngo.com.my/policies/customer-service-charter',
  feesCharges: 'https://www.touchngo.com.my/policies/touch-n-go-card-fees-and-charges',
  productDisclosure: 'https://assets.website-files.com/604f10ece77ed2575179c749/611ddfee49ba91c3dc148c67_touch_n_go_eWallet_product_disclosure_sheet_v0.8.pdf',
  fitmentCentres: 'https://rfidregister.touchngo.com.my/fitment-centres',
  plusHighwayTnC: 'https://www.plus.com.my/index.php?option=com_content&view=article&layout=edit&id=952&Itemid=417&lang=en',
  plusHighwayList: 'https://www.plus.com.my/index.php?option=com_content&view=article&id=4&Itemid=122&lang=en',
  bankNegaraMsia: 'https://www.bnm.gov.my/',
  bnmTelelink: 'https://telelink.bnm.gov.my/',
  ombudsmanFinancial: 'https://www.ofs.org.my/en/',
  mailToTelelink: 'https://telelink.bnm.gov.my/mailto:bnmtelelink@bnm.gov.my',
  privacyNotice: 'https://www.touchngo.com.my/policies/privacy-notice/',
  privacyNoticeNov21: 'https://www.touchngo.com.my/assets/documents/pages/home/TNG_Privacy-Notice_Revised_Nov-2021.pdf',
  shopeeRFIDTagPurchase: 'https://shopee.com.my/Touch-%E2%80%98n-Go-Self-fitment-DIY-RFID-Tag-i.255807619.7844939231?smtt=9&utm_campaign=s255807619_ss_my_webs_mx-portal&utm_content=redirection&utm_medium=seller&utm_source=website',

  // Recaptcha
  // siteKey: "6LcTSLUUAAAAAARfUxB4clv6NJn0crkD3PhTLiYc",
  siteKey: "6LeB5K0nAAAAANune_0pBeYfZPrVwb2DP-8nKgEB",

  // GA
  trackingID: "",

  // Default Value
  localCountry: 'MYS',

  // Max Listing
  takeCount: 20,
  minYear: 1960,

  // File Upload
  allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'text/csv', 'application/vnd.ms-excel', 'application/zip', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  maxUploadFile: 5, // Number of Files
  maxFileSize: 20 * 1024 * 1024,  // MB

  // Regex
  urlRegexp: /^(http?|https|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
  emailRegexp: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  mobileRegexp: /^[0-9]{9,10}$/,
  rfidTagIdRegex: /^[a-zA-Z0-9]{24}$/,
  maxTagIdLength: 24,
  minPasswordLength: 8,
  maxPasswordLength: 15,
  pinLength: 6,
  cardSerialNoRegexp: /^[0-9]{9,10}$/,
  cardSerialNoLength: 10,
  maxFullNameLength: 100,
  maxEmailLength: 100,
  addressRegex: /^[a-zA-Z0-9.,-/\\s#' ]+$/,
  cityRegex: /^[0-9a-zA-Z\\s ]+$/,
  nameRegex: /^[a-zA-Z0-9-/\\s@()'. ]+$/,

  // Input Length
  maxTextareaInput: 8000,
  maxTextInput: 100,
  maxDigitInput: 4,
  maxDecimalInput: 15,

  gMapsApiKey: 'AIzaSyCqL9UDBa_nGWrdvXS9KpDGiBN-HfZYDxw',

  // Key
  plrtpPublicKey: "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzPhO0hb1BYouejL0LmUz\nFfgVI8v87e9vRfBd9VEEkbRSmkHOz2AyPJ/QwJSj2zxNO0U9qwtsCBA1McG+1vB+\nK6booaEUu1azHZxaKMO3tWnmx53HvzCuzzbDT9nb2hnttLdLS4RYYrs0Uo6eCcLd\n41GQovCtyYm5bAxcrD9V/PWr95I/fRZzi41PNUJv6MQLvPb4hfPLSWjEbpN/TWyf\nrKtr/952yIDsA38jnDuw4AG82fuCvBRB+KX0vGoOgYdW/tIjUx89hP37EDd2ZiFR\nK9Dqnt4yi7VHbQHweDBovrmJDU6O7vxJBhKWpIOJYWpCPaCmhkFLbc4W3xyGJYE2\nqQIDAQAB\n-----END PUBLIC KEY-----",
  plrtpPrivateKey: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDM+E7SFvUFii56\nMvQuZTMV+BUjy/zt729F8F31UQSRtFKaQc7PYDI8n9DAlKPbPE07RT2rC2wIEDUx\nwb7W8H4rpuihoRS7VrMdnFoow7e1aebHnce/MK7PNsNP2dvaGe20t0tLhFhiuzRS\njp4Jwt3jUZCi8K3JiblsDFysP1X89av3kj99FnOLjU81Qm/oxAu89viF88tJaMRu\nk39NbJ+sq2v/3nbIgOwDfyOcO7DgAbzZ+4K8FEH4pfS8ag6Bh1b+0iNTHz2E/fsQ\nN3ZmIVEr0Oqe3jKLtUdtAfB4MGi+uYkNTo7u/EkGEpakg4lhakI9oKaGQUttzhbf\nHIYlgTapAgMBAAECggEAaCNMYJ87U9o17O4Qc7YE84d8rhhI5FZ/EWijRC0qhiC4\nyxjeSyxTuct00zNx4MRNdOyj/VArThl1/VazcB7165W8ocnmb5wIGuZCRG3T2373\nurmmL7Q7TJpFNz+33KLJy8RIpBCUm20Dk2JUVx9DfugE9VQU581qgMPzHax8Mq1x\nc9oYCvat8GHhkF1H25IweIvBBJhc+wsaYpeetGvIgaU2vr+8jEdaiKsZ2d2/7gnL\nkNm72QQ6HCo4kIgtGKAJUf2uuLpp2z6k6sh/bEzrpliFUVp1wcVdcVG4C+JaDR0F\n1xBFXZehhdzUxt+DHRlFgYgK/M+1aCKzfQ9Nw5T9gQKBgQD4I82qu6Daah6axGNw\nX+OkYCP5JqZbcK7bfoSOIvsWla/z69L0l3E5MS9vmHRDhTL95Q5T17UQLNLEoyEK\niHD1TKEdZKIb34/QZzuPfUK6gD0lUP3/dTdiyNaJkE7mtX4RArjnw2eJPfuJ/o/c\nIuGLKSnnvDUA4Q3pSwuI155qkQKBgQDTdm83wbExCYHxC+iRicSgFPGdtD+b2BRq\nCxkuQeGxtEX5Ybt6WXEDBNOpnNWs4LPukOATqWi6JYfKPrjQq4K6CQwPRRMFBG6K\nyN1Q5zi2TkzsxQY5tqXBeCj2QjlXK3Xczfnp89sO+c7dsJ0JVrQOvo6u0lSq/nn0\njD2+sOImmQKBgQCjkPZBVOzzDICgBFSH/q1P7Q/JbHC8VcrtZLh7+RMKECGr6Adm\ns20vBfz+0r/QwjyaoCKRod74ipNkiyN8jfPnvlt1xKAXP4bNGT9plmgmB+JGdtz3\nR4z60OiaRUW9TmWLUQARxfy5uuHUVLj/ov6JfNjiqG1H5xIEdjNkQL1sAQKBgBfj\nzXTTIR2VIWUGH2Qx2FDtjM5wSq9kngnM7y9WqhTVdQJTlEaiTC43IWKMIWfCixFs\ntKQEA5zkGI80vtR0S/SQiRhAiYGLmqF7R+WX+cQ+3FnzeFyU0e0lkhsgKDI86rfj\nXVSdBCaQ+ln5VgR0EyuHQ79ka8RVs5l5LpCYkzQhAoGAUsls2wCzJQTLbuYeay+7\nofFCKmioDT1olO2cVB7PAXfy6XhVmaSBp0vK28XDIztEOuxJIN5ruDd6wrRcWPBS\n1dSPkMWV7RATUzp6ItlcSvWVmG8qTzAkBxeKt65Kq+yiVUfoKaPPSvSc8Bvr+/P1\nsuIpCtOegm19X1ig4AI+pW0=\n-----END PRIVATE KEY-----",
  // publicKey: "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwk24GzC3EwRJMhc2xTXT\nVtLQ7NFe5uZD/yGbgDk5nuQma7hkXbxRPGLezx3ZVxhC5b8gWR6RWIfuf9s+SLPq\nzkJ8KJQeotoSBNjOfEFnqe/hhqESy6Xm0dm35Kra5qDpyC/Oa31TcMcK5jJJClxG\ntFjwNAykKCU0HE3MkuUN2NtvCXc94q9K0S8V2GxJL7xEMI3KIToExk+vaf8fUxlX\nn1v7mXpD2fgUFNRDGUW5sonK0l0XBvotfVZiEPTAsudJfdExWVcEOCvi5IoNEMAS\nWexwUuziM6il5pxhw5Zw/rsAGLMvmIE42+fjsl5MGk9HWZhMt+gv8lWzluRVvVBj\nOwIDAQAB\n-----END PUBLIC KEY-----",
  // privateKey: "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCxyRecPmJLN1WG\nw+mXOEu4YE0tlQQfHeRf1jhXIEGdK6JivrUHpsKVf/lrWoBI5GYoW3RbY5JEo9uT\nnRMprPuQkJbuwktyZYvPQxA+g9fPG7mNtK3qsYu57mboAfv9FKKwcEbGcQJ+bsTR\n25iMP7N7hHQINGUgCjBf2cSQN2uCWdIwU8vx0mtaY+MKYIR0lrVUfDpOhxqKTLq3\ngYTFloQp3mDgUkmkvuRHcMG+0TjYOBTmEojKOpFDbatnEDvtWQB9LARNO+moN2Ja\nKl68CvQrc+uehd9u4n/xFDxSZZ335jSYEcsOkWkz4T4FBc67XVCdqJ82Bn9u6JkL\nVOF4tW6BAgMBAAECggEAPrMl8YOK1O4R6rJjSmKfScokzeOVDYhUR3xcaFXG7jA6\nV8rcO3OTgngctcC4J3ztjmQamDR4nF6RjPDDllocanKj2FaLly+67ADB1NpYnQEx\nIonLIE83cP5kKoSwoyPI8KLYQxoOABr+rOheV3AL0472AQLBe1g8kUFS8m2pK9H8\ncb59IVIZ07fbnukRuGGowv7JYutE3qT/mZ6Vw3MDpIGVF32I1tEo2HI30d8hapjS\nAJQZLcLuJnGGb3iCyfaLTJ17enCtg67Dx7IFNpsY2gZh3gOKgQGIZvBlXnp4dG7A\n9x7je9tkOpVgJEXQaXZuizaMNThx6Tqkclg6qts+sQKBgQDr8+BBVJVC/Xx3W/lA\n+Dzl9340v0Lx1FU4Da6xh6/OyC7ZYn+E1jwf8qhPq/qzyVsX5utg0jnnUrMn2TEm\nHL3D5iPerm4t1thNZK0UyEYI3aDuIEfT/RCxvcA9sj/kULyCeMUyUYkx+PxMQqjG\nn9mgMU1vLiV2iuN5yZzZwxyqhQKBgQDA5AsVvf/LfhSEtzN/lx4Kz+OI5CgbFphx\nNjiZM/2aBJ9aBg0oa6VzHXf5ssxhy4eNY+vkG4iDHWEtEptllD/eIcW+xO/IJw1p\ny9AERBx4Uo/nagvXq1NpmlZZtH7rzmckIQ0DexY8rgMB+nmZp4RaiF3O116zpz1h\nevlx57/6zQKBgQDBDS99pGvffLD6o6jjTHffHEkJdTebz0POcftty3u/rzANsrZt\nBC/7X7nwPeEIeoXqn4gUFs1Dg/mLb/vzl+53uvO8PXFuVetyHx4x887BcDw8uPKr\n8tFW686/a/k0oA4sng2SJv4egf5DkwKuSJlxpQUbI2GJhQerXJbV4PqyVQKBgFTP\n8E/PCvnepgb0xqADQiJe5hz6tOe0N7MPtDMouQlPnwFmR8LOEiFbg7U2+MbHRwLU\nPJQ/xvZfpKq807cGSE7Taq9GyGepRCyQgRZfHGWLZ9rItgxOOI7mXbFzu5rTVmZ8\nYcx2AoELwibMi0sUbQ2hiB26dNlpX4PNHoEG9VLBAoGAapWNeJVdoeSLO566Uk65\ntKOyrLlb/4LvMzfMIQ3WhTsK5AsRlB2Z2klqZvy54TEhl9c5qbAsT6CH1O6eHaAp\n/uDk9uwm96VICRUMsd9+jrUY9tgbsBaz7odB3XqVQ1JU53jMO96VPd1zWXCVxA4W\nW7pWrt8BEX+H/fyf9xFk5Qk=\n-----END PRIVATE KEY-----",

  otpTimer: 120
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
