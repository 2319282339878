import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mx-tnc',
  templateUrl: './mx-tnc.component.html',
  styleUrls: ['./mx-tnc.component.scss']
})
export class MxTncComponent implements OnInit, AfterViewInit {

  @ViewChild("termsTabGroup", { static: false }) termsTabGrp: MatTabGroup;

  targetTab: number;

  constructor(public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let tabId: string = this.route.snapshot.paramMap.get("tabid");

    this.targetTab = tabId ? Number(tabId) : 1;
  }

  ngAfterViewInit() {
    const tabGroup = this.termsTabGrp;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

    const tabCount = tabGroup._tabs.length;
    if (this.targetTab < 1 || this.targetTab > tabCount)
      this.router.navigate(['/terms-of-use']);

    tabGroup.selectedIndex = this.targetTab - 1;
  }
}
