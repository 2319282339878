import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'unify-success-modal',
  templateUrl: './unify-success-modal.component.html',
  styleUrls: ['./unify-success-modal.component.scss']
})
export class UnifySuccessModalComponent implements OnChanges {


  @Input('unifySuccessTitle') unifySuccessTitle: string = '';
  @Input('unifySuccessDesc') unifySuccessDesc: string = '';
  @Input('unifySuccessEmail') unifySuccessEmail: boolean = false;
  @Input('unifyIsBackToWebsite') unifyIsBackToWebsite: boolean = false;
  @Input('disabledClose') disabledClose: boolean = false;
  @Input('refundCaseNo') refundCaseNo: string = '';
  @Output() routerBtn: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('unifySuccessModal', { static: false }) unifySuccessModal: TemplateRef<any>;

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnChanges() {
  }
  openModal() {
    this.dialog.open(this.unifySuccessModal, {
      width: '720px', disableClose: this.disabledClose
    });
  }

  modalClose() {
    this.dialog.closeAll();
  }

  routerBtnClick() {
    // this.routerBtn.emit(true);
    window.location.href = 'https://www.touchngo.com.my'
  }
}
