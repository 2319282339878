import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/api-service/authentication.service";
import { StatusVerification, ePaymentCard } from "src/app/helpers/enums";
import { ShopeeRedirectModalComponent } from "../shopee-redirect-modal/shopee-redirect-modal.component";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  templateId: StatusVerification;
  statusPageType: number;

  payCardType = ePaymentCard;

  email: string;
  cardType: string;
  cardNo: string;
  amount: number;
  paymentId: string;
  orderID: string;

  hasLogin: boolean;
  isShopeeLoading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
    this.templateId = this.route.snapshot.data["template"];
    this.statusPageType = this.templateId.valueOf();
    const tokenId = this.authService.currentTokenValue;
    this.hasLogin = tokenId ? true : false;

    if (this.statusPageType >= 7) {
      let stateHistory = history.state.data;
      if (stateHistory == undefined) {
        this.router.navigate(["/payment-cancelled"]);
      } else {
        this.orderID = stateHistory.orderID;
        this.email = stateHistory.email;
        this.cardType = stateHistory.paymentInfo.paymentCardType;
        this.cardNo = stateHistory.paymentInfo.paymentCardNo;
        this.amount = stateHistory.paymentInfo.paymentAmount;
        this.paymentId = stateHistory.paymentInfo.paymentId;
      }
    }
  }

  ngOnInit() {}

  login() {
    this.router.navigate(["/login"]);
  }

  register() {
    this.router.navigate(["/registration"]);
  }

  forgot() {
    this.router.navigate(["/forgot-password"]);
  }

  continueShopping() {
    this.router.navigate(["/buy-rfid-tag"]);
  }

  myOrders() {
    this.router.navigate(["/my-orders"]);
  }

  home() {
    this.router.navigate(["/main"]);
  }

  registerUser() {
    this.router.navigate(["/register"]);
  }

  reorder() {
    // this.router.navigate(['/rfid-tag-purchase'])

    // Open the modal when reorder() is called
    this.dialog.open(ShopeeRedirectModalComponent, {
      panelClass: "shopee-redirect-modal",
    });
  }
}
