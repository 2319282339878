import { Component, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  @Input() fcontrol: FormControl;
  @Input() isSubmit: boolean;

  errMsg: string = null;

  constructor(private el: ElementRef) { }

  setBorder(fc: FormControl) {
    if (fc == undefined || fc == null)
      return;

    let err = fc.errors;
    let previous = this.el.nativeElement.previousSibling;
    this.errMsg = null;

    if (err != null) {
      if (err["required"])
        this.errMsg = "Required";
      else if (err["docRequired"])
        this.errMsg = "Please upload at least 1 attachment";
      else if (err["minlength"])
        this.errMsg = "Minimum characters: " + err.minlength["requiredLength"];
      else if (err["maxlength"])
        this.errMsg = "Maximum characters: " + err.maxlength["requiredLength"];
      else if (err["min"])
        this.errMsg = "Minimum: " + err.min["min"];
      else if (err["max"])
        this.errMsg = "Maximum: " + err.max["max"];
      else if (err["email"])
        this.errMsg = "Incorrect email format";
      else if (err["pattern"])
        this.errMsg = "Invalid";
      else if (err["invalidDateRange"])
        this.errMsg = "End date should not earlier than start date";
      else if (err["activeIncentive"])
        this.errMsg = "The incentive is still valid";
      else if (err["invalidYear"])
        this.errMsg = "Please key in valid year";
      else if (err["invalidRate"])
        this.errMsg = "Please key in valid percentage rate";
      else if (err["zeroVal"])
        this.errMsg = "The value cannot be 0";

      if (previous && previous.classList)
        previous.classList.add("is-invalid");
    }
    else {
      if (previous && previous.classList)
        previous.classList.remove("is-invalid");
    }

    err = null;
    previous = null;
  }
}
