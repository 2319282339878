import { Component, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnChanges {

  errorMsg: string = '';
  closeBtn: string = 'OK';
  showErrorModal: boolean = false;

  @ViewChild('errorModal', { static: false }) errorModal: TemplateRef<any>;

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnChanges() {
    if (this.showErrorModal) {
      this.dialog.open(this.errorModal);
    } else {
      this.modalClose();
    }
  }

  openModal(msg: string, routePath: string = null, btnLabel: string = "OK") {
    this.errorMsg = msg;
    this.closeBtn = btnLabel;

    let dialogRef = this.dialog.open(this.errorModal);

    dialogRef.afterClosed().subscribe(result => {
      if (routePath != null) {
        this.router.navigate([`/${routePath}`]);
      }
    });
  }

  modalClose() {
    this.dialog.closeAll();

    this.showErrorModal = false;
  }
}
