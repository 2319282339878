import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Product } from '../models/product';
import {
  OrderFeesRequest, OrderFeesResponse, SaveOrderRequest, OrderPaymentRequest, OrderPaymentResponse,
  UpdateOrderRequest, UpdateOrderResponse, PaginatedOrderResponse, CalcTotalOrderFeeRequest, CalcTotalOrderFeeResponse, SingleOrderResponse,
  DeleteOrderResponse, OrderCancellationRequest, OrderCancellationResponse, GetOrderRequest
} from '../models/order';
import { PaymentMethod } from '../models/payment';
import { map } from 'rxjs/operators';
import { SignatureService } from '@services/signature.service';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private httpClient: HttpClient,
    private signatureService: SignatureService,
  ) { }

  ////  #region Buy RFID Tag
  //  Get Product List 
  public getProductRequest(): Observable<Product[]> {
    const url: string = internalTest ? 'assets/json/order/product.json' : `${apiURL}products`;
    let resp = this.httpClient.get<Product[]>(url, { headers: header })
      .pipe(
        map(products => products.filter(item => item.productCategory === 'RFID'))
      )
    return resp;
  }

  //  Get Order Fees 
  public getOrderFees(orderFeesRequest: OrderFeesRequest): Observable<OrderFeesResponse> {
    if (internalTest)
      return this.httpClient.get<OrderFeesResponse>('assets/json/order/orderfeesresponse.json', { headers: header });
    else
      return this.httpClient.post<OrderFeesResponse>(`${apiURL}order-fee`, orderFeesRequest, { headers: header });
  }

  // Get List of Payment Methods
  public getPaymentMethods(): Observable<PaymentMethod[]> {
    const url: string = internalTest ? 'assets/json/order/paymentresponse.json' : `${apiURL}data/payment-methods`;

    return this.httpClient.get<PaymentMethod[]>(url, { headers: header });
  }

  // Get List of Payment Method without authenticate
  public getPublicPaymentMethods(): Observable<any> {
    let request = this.signatureService.signRequest('rfid.pruchase.payment.methods', {});

    const url: string = internalTest ? 'assets/json/order/paymentresponse.json' : `${apiURL}public/payment-methods`

    return this.httpClient.post<any>(url, request, { headers: header });
  }


  // Save Order
  public saveOrder(saveOrderRequest: SaveOrderRequest): Observable<OrderFeesResponse> {
    if (internalTest)
      return this.httpClient.get<OrderFeesResponse>('assets/json/order/saveorderresponse.json', { headers: header });
    else
      return this.httpClient.post<OrderFeesResponse>(`${apiURL}order-request`, saveOrderRequest, { headers: header });
  }

  // Submit Order Payment
  public submitOrderPayment(orderPayment: OrderPaymentRequest): Observable<OrderPaymentResponse> {
    if (internalTest)
      return this.httpClient.get<OrderPaymentResponse>('assets/json/order/orderpaymentresponse.json', { headers: header });
    else
      return this.httpClient.post<OrderPaymentResponse>(`${apiURL}order-request/payment`, orderPayment, { headers: header });
  }

  public submitPublicOrderPayment(orderPayment: OrderPaymentRequest): Observable<any> {
    let signedReq = this.signatureService.signRequest('rfid.payment.order.submission', orderPayment);

    if (internalTest)
      return this.httpClient.get<OrderPaymentResponse>('assets/json/order/orderpaymentresponse.json', { headers: header });
    else
      return this.httpClient.post<OrderPaymentResponse>(`${apiURL}public/order-request/payment`, signedReq, { headers: header });
  }

  // Update Order
  public updateOrder(orderReqId: string, updateVehicleRequest: UpdateOrderRequest): Observable<UpdateOrderResponse> {
    if (internalTest)
      return this.httpClient.get<UpdateOrderResponse>('assets/json/order/updateorderresponse.json', { headers: header });
    else
      return this.httpClient.put<UpdateOrderResponse>(`${apiURL}order-request/${orderReqId}`, updateVehicleRequest, { headers: header });
  }

  // Get List of All Orders 
  public getOrders(orderReq: GetOrderRequest): Observable<any> {
    if (internalTest) {
      return this.httpClient.get<PaginatedOrderResponse>('assets/json/order/paginatedorderresponse.json', { headers: header })
        .pipe(map(resp => {
          let result: PaginatedOrderResponse = resp;

          const req = resp.orderRequests;
          let startIndex: number = (orderReq.page - 1) * orderReq.limit;
          let endIndex: number = orderReq.page * orderReq.limit;
          let trxList = req.slice(startIndex, endIndex);

          result.orderRequests = trxList;
          result.currentPage = orderReq.page;

          return result;
        }));
    }
    else
      return this.httpClient.post<any>(`${apiURL}order-request`, orderReq, { headers: header });

  }
  public getPublicOrders(orderReq: GetOrderRequest): Observable<any> {
    let signedReq = this.signatureService.signRequest('rfid.payment.order', orderReq)
    if (internalTest) {
      return this.httpClient.get<PaginatedOrderResponse>('assets/json/order/paginatedorderresponse.json', { headers: header })
        .pipe(map(resp => {
          let result: PaginatedOrderResponse = resp;

          const req = resp.orderRequests;
          let startIndex: number = (orderReq.page - 1) * orderReq.limit;
          let endIndex: number = orderReq.page * orderReq.limit;
          let trxList = req.slice(startIndex, endIndex);

          result.orderRequests = trxList;
          result.currentPage = orderReq.page;

          return result;
        }));
    }
    else
      return this.httpClient.post<any>(`${apiURL}public/order-request`, signedReq, { headers: header });

  }


  // Calculate Total Order Fee
  public calcTotalOrderFee(orderPayment: CalcTotalOrderFeeRequest): Observable<CalcTotalOrderFeeResponse> {
    if (internalTest)
      return this.httpClient.get<CalcTotalOrderFeeResponse>('assets/json/order/calctotalorderfeeresponse.json', { headers: header });
    else
      return this.httpClient.post<CalcTotalOrderFeeResponse>(`${apiURL}order-calculate`, orderPayment, { headers: header });
  }

  // Get Single Order Request
  public getSingleOrder(orderReqId: string): Observable<SingleOrderResponse> {
    const url: string = internalTest ? 'assets/json/order/singleorderresponse.json' : `${apiURL}order-request/${orderReqId}`;

    return this.httpClient.get<SingleOrderResponse>(url, { headers: header });
  }

  public getSinglePublicOrder(orderReqId: string): Observable<any> {
    let signedReq = this.signatureService.signRequest('rfid.payment.status', {})

    if (internalTest) {
      return this.httpClient.get<SingleOrderResponse>('assets/json/order/singleorderresponse.json', { headers: header });
    } else {
      return this.httpClient.post<any>(`${apiURL}public/order-request/${orderReqId}`, signedReq)
    }
  }

  // Delete Order Request
  public deleteOrder(orderReqId: string): Observable<DeleteOrderResponse> {
    if (internalTest)
      return this.httpClient.get<DeleteOrderResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.delete<DeleteOrderResponse>(`${apiURL}order-request/${orderReqId}`, { headers: header });
  }

  // Submit Order Cancellation Request
  public submitOrderCancellation(orderReqId: string, orderCancel: OrderCancellationRequest): Observable<OrderCancellationResponse> {
    if (internalTest)
      return this.httpClient.get<OrderCancellationResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<OrderCancellationResponse>(`${apiURL}order-request/cancel/${orderReqId}`, orderCancel, { headers: header });
  }


  ////  #endregion
}
