import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class MfaService {

  constructor(private httpClient: HttpClient) { }

  public requestOtp(): Observable<any> {
    return this.httpClient.post<any>(`${apiURL}request-otp`, { headers: header });
  }

  public verifyOtp(otp: any): Observable<any> {
    return this.httpClient.post<any>(`${apiURL}verify-otp`, otp, { headers: header });
  }
}
