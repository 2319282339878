import { Component, OnInit, HostListener } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  selector: "shopee-redirect-modal",
  templateUrl: "./shopee-redirect-modal.component.html",
  styleUrls: ["./shopee-redirect-modal.component.scss"],
})
export class ShopeeRedirectModalComponent implements OnInit {
  shopeeRFIDTagUrl = environment.shopeeRFIDTagPurchase;
  mobile: boolean;

  constructor(private dialogRef: MatDialogRef<ShopeeRedirectModalComponent>) {
    this.mobile = window.innerWidth <= 767;
  }

  ngOnInit() {}

  redirectToShopee() {
    window.location.href = environment.shopeeRFIDTagPurchase;
    this.dialogRef.close();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.mobile = event.target.innerWidth <= 767; // Update value on window resize
  }
}
