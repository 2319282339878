import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { LandingComponent } from './landing/landing.component';
import { SupportComponent } from './support/support.component';
import { MxTncComponent } from './mx-tnc/mx-tnc.component';
import { FaqComponent } from './faq/faq.component';
import { SelfFitmentComponent } from './self-fitment/self-fitment.component';
import { FitmentCentresComponent } from './fitment-centres/fitment-centres.component';
import { RfidHighwaysComponent } from './rfid-highways/rfid-highways.component';
import { BuyRfidComponent } from './buy-rfid/buy-rfid.component';
// import { RfidTagPurchaseComponent } from './rfid-tag-purchase/rfid-tag-purchase.component';

const routes: Routes = [
  {
    path: 'main',
    component: LandingComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'rfid-usp',
    component: LandingComponent,
    data: { layout: 'pre-login', anchor: 'rfid-usp' }
  }, {
    path: 'support',
    component: SupportComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'faq',
    component: FaqComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'terms-of-use',
    component: MxTncComponent,
    data: { layout: 'pre-login', title: 'Terms of use' }
  }, {
    path: 'self-fitment',
    component: SelfFitmentComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'self-fitment-vid',
    component: SelfFitmentComponent,
    data: { layout: 'pre-login', anchor: 'sf-vid' }
  }, {
    path: 'fitment-centres',
    component: FitmentCentresComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'rfid-enabled-highways',
    component: RfidHighwaysComponent,
    data: { layout: 'pre-login' }
  }, {
    path: 'buy-rfid-tag',
    component: BuyRfidComponent,
    data: { layout: 'pre-login' }
  }, 
  // {
  //   path: 'rfid-tag-purchase',
  //   component: RfidTagPurchaseComponent,
  //   // canActivate: [AuthGuard],
  //   // data: { layout: 'purchase' }
  //   data: { layout: 'pre-login' }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreLoginRoutingModule { }
