export class Request {
    header: any;
    body: any;

    constructor(state?: Partial<Request>) {
        state = state || {};
        this.header = state.header;
        this.body = state.body;
    }
}

export class Body {
    request: any;
    signature: any;

    constructor(state?: Partial<Body>) {
        state = state || {}
        this.request = state.request;
        this.signature = state.signature;
    }
}

export class Headers {
    requestId: string;
    clientId: string;
    function: string;
    timestamp: any;
    reserve: string;
    version: string;

    constructor(state?: Partial<Headers>){
        state = state || {}
        this.requestId = state.requestId;
        this.clientId = state.clientId;
        this.function = state.function;
        this.timestamp = new Date().toISOString();
        this.reserve = "{}";
        this.version = "1.0";
    }
}