import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PreLoginRoutingModule } from './pre-login-routing.module';
import { LandingComponent } from './landing/landing.component';
import { SupportComponent } from './support/support.component';
import { SelfFitmentComponent } from './self-fitment/self-fitment.component';
import { FitmentCentresComponent } from './fitment-centres/fitment-centres.component';
import { RfidHighwaysComponent } from './rfid-highways/rfid-highways.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

import { RfidTncComponent } from './rfid-tnc/rfid-tnc.component';
import { MxTncComponent } from './mx-tnc/mx-tnc.component';
import { FaqViewComponent } from './faq-view/faq-view.component';
import { FaqComponent } from './faq/faq.component';
import { BuyRfidComponent } from './buy-rfid/buy-rfid.component';
// import { RfidTagPurchaseComponent } from './rfid-tag-purchase/rfid-tag-purchase.component';

import { Ng2OdometerModule } from 'ng2-odometer';

//directive
import { TooltipCustomActionModule } from 'src/app/directives/tooltip-custom-action/tooltip-custom-action.module';
import { ShopeeRedirectModalComponent } from '../shared-module/shopee-redirect-modal/shopee-redirect-modal.component';

@NgModule({
  declarations: [
  	LandingComponent, 
  	SupportComponent, 
    RfidTncComponent, 
    MxTncComponent, 
    FaqViewComponent, 
    FaqComponent, 
    SelfFitmentComponent, 
    FitmentCentresComponent, 
    RfidHighwaysComponent,
    BuyRfidComponent,
    // RfidTagPurchaseComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PreLoginRoutingModule,
    SlickCarouselModule,
    MatButtonModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    Ng2OdometerModule,
    TooltipCustomActionModule,
    SharedModuleModule,
  ],
  entryComponents: [ShopeeRedirectModalComponent],
})
export class PreLoginModule { }
