import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { StatusVerification } from './helpers/enums';

import { PreLoginRoutingModule } from './content-pages/pre-login/pre-login-routing.module';

import { AppComponent } from './app.component';
import { StatusComponent } from './content-pages/shared-module/status/status.component';

const routes: Routes = [
  {
    path: '',
    // redirectTo: '/main',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'request-payment',
    component: AppComponent,
    data: { purpose: 'payment-processing' }
  },
  {
    path: 'login',
    loadChildren: () => import('./content-pages/system-authentication/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./content-pages/system-authentication/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./content-pages/system-authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'set-new-password',
    loadChildren: () => import('./content-pages/system-authentication/set-new-password/set-new-password.module').then(m => m.SetNewPasswordModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./content-pages/system-authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'email-confirmation/:token',
    loadChildren: () => import('./content-pages/system-authentication/email-activation/email-activation.module').then(m => m.EmailActivationModule)
  },
  {
    path: 'email-verification/:token',
    loadChildren: () => import('./content-pages/system-authentication/change-email/change-email.module').then(m => m.ChangeEmailModule)
  },
  {
    path: 'resend-activation',
    loadChildren: () => import('./content-pages/system-authentication/resend-activation/resend-activation.module').then(m => m.ResendActivationModule)
  },
  {
    path: 'fuel',
    loadChildren: () => import('./content-pages/fueling-recruitment/fueling-recruitment.module').then(m => m.FuelingRecruitmentModule)
  },
  {
    path: 'fuelling-locator',
    loadChildren: () => import('./content-pages/fuelling-locator/fuelling-locator.module').then(m => m.FuellingLocatorModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./content-pages/feedback/fueling-feedback.module').then(m => m.FuelingFeedbackModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./content-pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./content-pages/user-maintenance/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./content-pages/user-maintenance/edit-profile/edit-profile.module').then(m => m.EditProfileModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./content-pages/my-order/my-order.module').then(m => m.MyOrderModule)
  },
  {
    path: 'my-vehicle',
    loadChildren: () => import('./content-pages/vehicle-rfid/my-vehicle/my-vehicle.module').then(m => m.MyVehicleModule)
  },
  {
    path: 'my-request',
    loadChildren: () => import('./content-pages/vehicle-rfid/my-request/my-request.module').then(m => m.MyRequestModule)
  },
  {
    path: 'transaction-listing',
    loadChildren: () => import('./content-pages/transaction/my-transaction/my-transaction.module').then(m => m.MyTransactionModule)
  },
  {
    path: 'transaction',
    loadChildren: () => import('./content-pages/transaction/transaction.module').then(m => m.TransactionModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./content-pages/payment/order-payment/order-payment.module').then(m => m.OrderPaymentModule)
  },
  {
    path: 'payment-status',
    loadChildren: () => import('./content-pages/payment/payment-status/payment-status.module').then(m => m.PaymentStatusModule)
  },
  {
    path: 'link-wallet',
    loadChildren: () => import('./content-pages/user-maintenance/sof-binding/sof-binding.module').then(m => m.SofBindingModule)
  },
  {
    path: 'account-activation/success',
    component: StatusComponent,
    data: { template: StatusVerification.AccVerification_Success, layout: 'hide' }
  },
  {
    path: 'account-activation/failed',
    component: StatusComponent,
    data: { template: StatusVerification.AccVerification_Fail, layout: 'hide' }
  },
  {
    path: 'password-reset/failed',
    component: StatusComponent,
    data: { template: StatusVerification.ResetPassword_Fail, layout: 'hide' }
  },
  {
    path: 'email-verify/success',
    component: StatusComponent,
    data: { template: StatusVerification.ChangeEmailVerification_Success, layout: 'hide' }
  },
  {
    path: 'email-verify/failed',
    component: StatusComponent,
    data: { template: StatusVerification.ChangeEmailVerification_Fail, layout: 'hide' }
  },
  {
    path: 'payment-success',
    component: StatusComponent,
    data: { template: StatusVerification.Payment_Success, layout: 'hide' }
  },
  {
    path: 'payment-processing',
    component: StatusComponent,
    data: { template: StatusVerification.Payment_Processing, layout: 'hide' }
  },
  {
    path: 'payment-fail',
    component: StatusComponent,
    data: { template: StatusVerification.Payment_Fail, layout: 'hide' }
  },
  {
    path: 'payment-cancel',
    component: StatusComponent,
    data: { template: StatusVerification.Payment_Cancel, layout: 'hide' }
  },
  {
    path: 'processing-error',
    component: StatusComponent,
    data: { template: StatusVerification.Processing_Error, layout: 'hide' }
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./content-pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  { path: '**', 
    // redirectTo: '/main'
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: false }),
    PreLoginRoutingModule
  ],
  exports: [RouterModule],
  providers: [
    Title
  ]
})
export class AppRoutingModule { }
