import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../api-service/authentication.service';
import { RespStatus, LoginStatusCode, ForgotPasswordStatusCode, ValidateVehicleStatusCode, VerifySignUpStatusCode, BindSOFToMxAccountStatusCode } from './enums';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      //console.log("%c error = " + JSON.stringify(err), "color: blueviolet;");
      const apiURL = err.url;
      let respErr = err.error;


      if (err.status === RespStatus.Unauthorized) {
        this.authService.clearStorage();

        if (apiURL.includes("activate-account") || apiURL.includes("reset-password") || apiURL.includes("verify-email")) {
          if (respErr && respErr["statusCode"] == RespStatus.InvalidToken) {
            return throwError(respErr ? respErr.statusMessage : err.statusText);
          }
        }
        else {
          location.reload(true);
        }
      }
      else if (err.status === RespStatus.BadRequest) {
        if (respErr) {
          if (apiURL.includes("sign-up-verify") && respErr["statusCode"] == VerifySignUpStatusCode.EmailAreadyExist) {
            return throwError(VerifySignUpStatusCode.EmailAreadyExist);
          }
          if (apiURL.includes("authenticate") && respErr["statusCode"] == LoginStatusCode.AccountPendingActivation) {
            return throwError(LoginStatusCode.AccountPendingActivation);
          }
          if (apiURL.includes("forgot-password") && respErr["statusCode"] == ForgotPasswordStatusCode.AccountNotVerify) {
            return throwError(ForgotPasswordStatusCode.AccountNotVerify);
          }
          if (apiURL.includes("validate-vehicle") && respErr["statusCode"] == ValidateVehicleStatusCode.VehicleInfoNotFound) {
            return throwError(respErr);
          }
          if (apiURL.includes("validate-vehicle") && respErr["statusCode"] == ValidateVehicleStatusCode.VehiclePlateNumberInUse) {
            return throwError(respErr);
          }
          if (apiURL.includes("link-mx-to-ewallet") && respErr["statusCode"] == BindSOFToMxAccountStatusCode.NoVehicleActivated) {
            return throwError(respErr);
          }
          if (apiURL.includes("request-otp") || apiURL.includes("verify-otp")) {
            return throwError(respErr);
          }
        }
        else
          return throwError(err.statusText);
      }

      return throwError(respErr ? (respErr["statusMessage"] || respErr["message"] || respErr["error_description"]) : err["statusText"]);
    }));
  }
}
