export const environment = {
  production: true,
  apiEndpoint: '/mx/api/v1/',
  cmsEndpoint: 'http://47.254.196.25/',
  isInternalTest: false,   // warning: set to false for production deployment
  IRS_whiteList: false, // Set to false for public launching

  // Reference URL
  whistleblowingPolicy: 'https://www.touchngo.com.my/policies/whistleblowing-policy',
  antiBriCorrPolicy: 'https://www.touchngo.com.my/policies/anti-bribery-and-corruption-policy',
  termsOfUse: 'https://rfid.touchngo.com.my/terms-of-use',
  privacyPolicy: 'https://www.touchngo.com.my/policies/privacy-policy',
  custServiceCharter: 'https://www.touchngo.com.my/policies/customer-service-charter',
  feesCharges: 'https://www.touchngo.com.my/policies/touch-n-go-card-fees-and-charges',
  productDisclosure: 'https://assets.website-files.com/604f10ece77ed2575179c749/611ddfee49ba91c3dc148c67_touch_n_go_eWallet_product_disclosure_sheet_v0.8.pdf',
  fitmentCentres: 'https://rfidregister.touchngo.com.my/fitment-centres',
  plusHighwayTnC: 'https://www.plus.com.my/index.php?option=com_content&view=article&layout=edit&id=952&Itemid=417&lang=en',
  plusHighwayList: 'https://www.plus.com.my/index.php?option=com_content&view=article&id=4&Itemid=122&lang=en',
  bankNegaraMsia: 'https://www.bnm.gov.my/',
  bnmTelelink: 'https://telelink.bnm.gov.my/',
  ombudsmanFinancial: 'https://www.ofs.org.my/en/',
  mailToTelelink: 'https://telelink.bnm.gov.my/mailto:bnmtelelink@bnm.gov.my',
  privacyNotice: 'https://www.touchngo.com.my/policies/privacy-notice/',
  privacyNoticeNov21: 'https://www.touchngo.com.my/assets/documents/pages/home/TNG_Privacy-Notice_Revised_Nov-2021.pdf',
  shopeeRFIDTagPurchase: 'https://shopee.com.my/Touch-%E2%80%98n-Go-Self-fitment-DIY-RFID-Tag-i.255807619.7844939231?smtt=9&utm_campaign=s255807619_ss_my_webs_mx-portal&utm_content=redirection&utm_medium=seller&utm_source=website',

  // Recaptcha
  siteKey: "6LcTSLUUAAAAAARfUxB4clv6NJn0crkD3PhTLiYc",

  // GA
  trackingID: "UA-164258156-1",

  // Default Value
  localCountry: 'MYS',

  // Max Listing
  takeCount: 20,
  minYear: 1960,

  // File Upload
  allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'text/csv', 'application/vnd.ms-excel', 'application/zip', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  maxUploadFile: 5, // Number of Files
  maxFileSize: 20 * 1024 * 1024,  // MB

  // Regex
  urlRegexp: /^(http?|https|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
  emailRegexp: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  mobileRegexp: /^[0-9]{9,10}$/,
  rfidTagIdRegex: /^[a-zA-Z0-9]{24}$/,
  maxTagIdLength: 24,
  minPasswordLength: 8,
  maxPasswordLength: 15,
  pinLength: 6,
  cardSerialNoRegexp: /^[0-9]{9,10}$/,
  cardSerialNoLength: 10,
  maxFullNameLength: 100,
  maxEmailLength: 100,
  addressRegex: /^[a-zA-Z0-9.,-/\\s#' ]+$/,
  cityRegex: /^[0-9a-zA-Z\\s ]+$/,
  nameRegex: /^[a-zA-Z0-9-/\\s@()'. ]+$/,

  // Input Length
  maxTextareaInput: 8000,
  maxTextInput: 100,
  maxDigitInput: 4,
  maxDecimalInput: 15,

  gMapsApiKey: 'AIzaSyBFV4Ocb5Q54i9wYt0wEQs34rCyspuQ2D0',

  // Key 
  plrtpPublicKey: "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA02qHfQF5XBLUfTsRXe5t\necCq/8+kE68ctXv5Qk8mbbKMDpVMaCkw7UC4FxoHopA+OreuGRMq8RhL74HLPSSc\nA4U+3/8JsocRepeV/83RilIC3D2fRsSZJN47awf4cuEqa4A5EdUuFgBCDc26c6uK\n4wDaqBkxjZAqBHy1Gx7myLXwegeS21bbrNU7maa5jxx58KThyRxPKfNiMokflzYV\nWCQuhtQAqhfXiPPJ0HBbaEK7gOtBRnzSuJLr+lA9QG8t8Fk0ehj68O9QLiOHYZB0\nFB4FEKsfcZ6YBBnt8d7oLAvvzjitwBDAVvnubAhuKGyAmPZNiaMe8vZuvzhLRHcu\nkwIDAQAB\n-----END PUBLIC KEY-----",
  plrtpPrivateKey: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDTaod9AXlcEtR9\nOxFd7m15wKr/z6QTrxy1e/lCTyZtsowOlUxoKTDtQLgXGgeikD46t64ZEyrxGEvv\ngcs9JJwDhT7f/wmyhxF6l5X/zdGKUgLcPZ9GxJkk3jtrB/hy4SprgDkR1S4WAEIN\nzbpzq4rjANqoGTGNkCoEfLUbHubItfB6B5LbVtus1TuZprmPHHnwpOHJHE8p82Iy\niR+XNhVYJC6G1ACqF9eI88nQcFtoQruA60FGfNK4kuv6UD1Aby3wWTR6GPrw71Au\nI4dhkHQUHgUQqx9xnpgEGe3x3ugsC+/OOK3AEMBW+e5sCG4obICY9k2Jox7y9m6/\nOEtEdy6TAgMBAAECggEAKAlljSPJUslyGiEkgdBURRlaiMVOwjeITUokAw2wLlgA\ntAZMCPG8BUIbWqygQ4XjcwUz4CXEHRJH1xySW4riTLxgEtVehcOUaM3qJx5YD0TG\nq+SzlBhJFDVNtVA2GbNQcDlBFYJ0jtAPskOCisMysxiHUAwtWSyy+FSRi4hHljyd\n8ye5KlX8cYuoXP83pzjuQpXpGbO2r1VvlGaHV1OwzbS8hXJ5R4x+jqUwfV/Cfnf/\nlFUPhw/YbX8C81qceJ3OfJinJo5YjWYFa6UTdysqHZBLrxJ0522MPYFJ1JaPGZam\n861Q4hysmSIXu4gXx2DHpMyojwPWV2gKvnbdYqeEoQKBgQD5DUgjPLiNUmQ6hz2k\nR+JRL5ux8qfGQNaewhGFc9FDU5XxllI3g/cfnmmejYNujKeG+vF4mjXwNSGmkaSi\nHEpG5O+KQr4emvphqPXlXVSyEd65XDInNW/vwKJxJmU+HgXPeY5UyIqT/NT9DMhe\nSn8ISEdwv7jspv1sHYg0UCkz8QKBgQDZUHRY/Z9iGlS/TMsMHF+Gbjxzey9n/TiX\nQoywJmzh/tSx2Uf+siCCjTVZOiIf55SP9JWaIt+oa9DOOCT1HGH5/gstsAMHfhBu\nWg7QZrv9O+6ec83upV60PyUThJKbzG+EtwI/ZsDXjx7XD1hqeJOJUgR/cDNPtKVo\nEGZ5ucR+wwKBgG9utOcnRP/ebIAPgNIfY7Cll4kwJEc9PYn9FS/b2TbBkGZutsym\nHA0mqOVLNhJhlpd7ck1e2mFmtG693aa297juuArpv/0bpInLunHsp3ehLUFMchg+\n/Z2NWGE+J7B6aGkjXz6qF+8NAhNTA9fEh0AibuyQq+Ie+WbFc+rMLxNRAoGBAKeP\n/rZjkVLOpMwAxYuoEQwR0fDlai9PtCQFv39PkaNMn+omdPnxlVm00g7j4U4ifY3m\ndLgt7bDquU1ZQz3wTae6eBoZXqyXSsocDNIqdQeU/7AT8RTzVCu9iLQdBAK3xsFZ\nb5U9gLJ1e70t6unl4TUAFdHBzP7Hdsbf5nQzhkyLAoGBALVWnYgo3VZdHCsdqwAU\n1xqkae66uvSRdfzz/Kz0Lu3hzWmbOpsh+nPExGIfCccmU55g3uojYkv3ym2eXGC1\nSp762MWDufJGTJX7I+KZAQnru6XvtX3c/ayKaRFMwYYOiXeWfKWyekADoyiK9Ntn\n7fbWar3HQQqs4bZoLwtu0dlK\n-----END PRIVATE KEY-----",

  otpTimer: 120
};
