import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MasterdataService } from 'src/app/api-service/masterdata.service';
import { RfidService } from 'src/app/api-service/rfid.service';
import { RefundRequestModel } from 'src/app/models/rfid';
import * as _moment from 'moment';
import { UserService } from 'src/app/api-service/user.service';

const moment = _moment;

@Component({
  selector: 'rfid-request-modal',
  templateUrl: './rfid-request-modal.component.html',
  styleUrls: ['./rfid-request-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RfidRequestModalComponent implements OnChanges, OnInit {
  @Input('yesBtn') yesBtn: string = '';
  @Input('subheader') subheader: string = '';
  @Input('header') header: string = '';
  @Input('selectedTrxDetails') selectedTrxDetails: any;
  @Input('selectedVecDetails') selectedVecDetails: any;

  @Output() actionEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() refundCaseNoEmit: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('unifyRequestModal', { static: false }) unifyRequestModal: TemplateRef<any>;
  @ViewChild('form', { static: false }) form: NgForm;

  requestErrorMsg = {
    requireError: "This field is required",
    invalidEmail: "Invalid Email Format",
    invalidMobileNo: "Invalid Mobile No. Format",
  };

  isTagNo: boolean = false;

  tagPlacements = [
    'Headlamp',
    'Windscreen (inside)',
    'Windscreen (outside)',
    'Others'
  ];

  reasons = [
    {
      code: '04',
      name: 'Double Deduction',
    },
    {
      code: '02',
      name: 'Overcharged',
    },
    {
      code: '01',
      name: 'Penalty',
    },
  ];
  entryLocs: any[] = [];
  customerId: any;
  customerInfo: any;

  maxDate = moment();

  requestForm: FormGroup;

  isLoadingSubmit: boolean = false;

  constructor(private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private masterDataAPI: MasterdataService,
    private rfidSrv: RfidService, private userService: UserService) {
    this.requestForm = this.formBuilder.group({
      vehicleColor: new FormControl("", [Validators.required]),
      tagPlacement: new FormControl("", [Validators.required]),
      reason: new FormControl("", [Validators.required]),
      tagNo: new FormControl("", []),
      remarks: new FormControl("", [Validators.required]),
      entryLoc: new FormControl("", []),
      exitLoc: new FormControl("", []),
      entryTransDate: new FormControl("", []),
    });
  }

  ngOnInit() {
    this.getTollPlaza();

    if (this.rfidSrv.getCustomerId() && this.rfidSrv.getCustomerId() !== 'null') {
      // console.log('has customer id');
      this.customerId = this.rfidSrv.getCustomerId();
      this.customerInfo = JSON.parse(this.rfidSrv.getCustomerInfo());
    } else {
      // console.log('no customer id');
      this.getProfile();
    }

    // this.customerId = this.rfidSrv.getCustomerId();
    // this.customerInfo = JSON.parse(this.rfidSrv.getCustomerInfo());

    this.requestForm.get('reason').valueChanges.subscribe((val) => {
      if (val === '01') {
        this.requestForm.get('entryLoc').setValidators([Validators.required]);
        this.requestForm.get('entryTransDate').setValidators([Validators.required]);

        this.requestForm.get('entryTransDate').setValue(this.selectedTrxDetails.entryDateTime);
        // this.requestForm.get('entryLoc').setValue(this.selectedTrxDetails.entryLocationName);
        this.requestForm.get('exitLoc').setValue(this.selectedTrxDetails.exitLocationName);

        this.requestForm.get('exitLoc').disable();
        this.requestForm.get('entryLoc').updateValueAndValidity();
        this.requestForm.get('entryTransDate').updateValueAndValidity();
        this.isTagNo = true;
      } else {
        this.requestForm.get('entryLoc').setValidators([]);
        this.requestForm.get('entryTransDate').setValidators([]);

        this.requestForm.get('entryLoc').updateValueAndValidity();
        this.requestForm.get('entryTransDate').updateValueAndValidity();
        this.isTagNo = false;
      }
    });
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.warn('selectedTrxDetails', this.selectedTrxDetails)
    console.warn('selectedVecDetails', this.selectedVecDetails)
    if (this.selectedTrxDetails) {
      this.maxDate = moment(this.selectedTrxDetails.exitDateTime);
    }
    if (this.requestForm) {
      this.requestForm.reset();
    }
  }

  getProfile() {
    this.userService.getProfile().subscribe(
      (res) => {
        this.customerId = res.customerAccountId;
        this.customerInfo = res;
        this.rfidSrv.saveCustomerId(res.customerAccountId);
        this.rfidSrv.saveCustomerInfo(JSON.stringify(res));
      },
      (err) => {
        this.actionEmit.emit('999');
      });
  }

  getTollPlaza() {
    this.masterDataAPI.getTollPlaza().then((res: any) => {
      res.entryPlazas.sort((a, b) => (a.locName > b.locName) ? 1 : -1);
      this.entryLocs = res.entryPlazas;
    });
  }

  openModal() {
    this.dialog.open(this.unifyRequestModal, {
      width: '900px',
    });
  }

  get rf() {
    return this.requestForm.controls;
  }

  get remarksHint() {
    if (!this.requestForm.get('remarks').value) {
      return 'Max 500 characters'
    } else {
      return 500 - this.requestForm.get('remarks').value.length + ' characters left'
    }
  }

  modalClose() {
    this.dialog.closeAll();
  }

  actionEmitCall() {
    this.form.onSubmit(undefined);

    if (this.requestForm.valid) {
      console.warn('valid', this.requestForm)
      let nowDateTime: any = moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      let convertDate: any = moment(this.selectedTrxDetails.transactionDateTime).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      let convertEntryDate = moment(this.selectedTrxDetails.entryDateTime).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      let convertExitDate = moment(this.selectedTrxDetails.exitDateTime).format("YYYY-MM-DD[T]HH:mm:ss[Z]");

      console.log(this.rfidSrv.getCustomerId());

      if (!this.customerInfo.idType || !this.customerInfo.idNumber) {
        // console.log('no idtype or number');
        this.actionEmit.emit('998');
      }

      if (this.customerId && this.rfidSrv.getCustomerId() !== 'null') {
        // console.log('has customer id');
        // call request refund API here
        let reqRefundPaylod: RefundRequestModel = {
          appSector: this.selectedTrxDetails.businessSectorName,
          subQueue: this.requestForm.get('reason').value,
          complaintDate: nowDateTime,
          customerInfo: {
            accountId: this.customerId,
            loginId: this.customerInfo.email,
            name: this.customerInfo.fullName,
            idNumber: this.customerInfo.idNumber,
            idType: this.customerInfo.idType.padStart(2, "0"),
            phoneNo: this.customerInfo.mobileCountryCode + this.customerInfo.mobileNo,
            nationality: this.customerInfo.nationality,
            email: this.customerInfo.email,
            extendedInfo: null,
            sofInfo: {
              sofProvider: null,
              sofOwnerName: this.customerInfo.fullName,
              sofOwnerIdNumber: this.customerInfo.idNumber,
              sofOwnerphoneNo: this.customerInfo.mobileCountryCode + this.customerInfo.mobileNo,
              sofOwnerEmail: this.customerInfo.email,
              sofOwnerAccountNo: this.selectedTrxDetails.ewalletAccountNo,
              sofIdType: this.customerInfo.idType.padStart(2, "0")
            }
          },
          paymentInfo: {
            ewalletAccountNo: this.selectedTrxDetails.ewalletAccountNo
          },
          deviceInfo: {
            deviceNumber: this.selectedTrxDetails.tagId,
            deviceStatus: this.selectedVecDetails[0].tagStatus
          },
          transactionInfo: {
            appSector: this.selectedTrxDetails.businessSectorCode,
            actualLocation: this.requestForm.get('reason').value === '01' ? this.requestForm.get('entryLoc').value.locName : null,
            actualSpid: this.requestForm.get('reason').value === '01' ? this.requestForm.get('entryLoc').value.spid : null,
            actualLocId: this.requestForm.get('reason').value === '01' ? this.requestForm.get('entryLoc').value.locId : null,
            entryLocName: this.selectedTrxDetails.entryLocationName,
            entrySpId: this.selectedTrxDetails.entrySpid,
            entrySpName: this.selectedTrxDetails.entrySpName,
            entryLaneId: this.selectedTrxDetails.entryLaneId,
            entryLocId: this.selectedTrxDetails.entryLocationId,
            entryDate: this.requestForm.get('reason').value === '01' ? moment(this.requestForm.get('entryTransDate').value).format("YYYY-MM-DD[T]HH:mm:ss[Z]") : convertEntryDate,
            exitSpId: this.selectedTrxDetails.exitSpid,
            exitSpLocName: this.selectedTrxDetails.exitLocationName,
            exitSpName: this.selectedTrxDetails.exitSpName,
            exitLaneId: this.selectedTrxDetails.exitLaneId,
            exitSpLocId: this.selectedTrxDetails.exitLocationId,
            exitDate: convertExitDate,
            txnId: this.selectedTrxDetails.transactionSerialNo,
            txnDateTime: convertDate,
            txnAmount: this.selectedTrxDetails.amount.replace('-', ''),
            txnType: this.selectedTrxDetails.transactionTypeCode,
            tngCardSerialNumber: this.requestForm.get('reason').value === '01' ? this.requestForm.get('tagNo').value : null,
          },
          vehicleInfo: {
            tagPlacement: this.requestForm.get('tagPlacement').value,
            vehicleColour: this.requestForm.get('vehicleColor').value,
            vehicleClass: '01',
            vehicleMake: this.selectedVecDetails[0].vehicleMake,
            vehicleModel: this.selectedVecDetails[0].vehicleModel,
            vehicleRegCountry: this.selectedVecDetails[0].vehicleRegisteredCountry,
            vehicleRegNo: this.selectedVecDetails[0].vehiclePlateNumber,
            vehicleType: this.selectedVecDetails[0].vehicleType,
            vehicleYear: this.selectedVecDetails[0].vehicleManufacturingYear,
          },
          cardInfo: {
            cardNumber: this.requestForm.get('tagNo').value,
          },
          caseRemarks: this.requestForm.get('remarks').value,
        }

        this.isLoadingSubmit = true;

        this.rfidSrv.createRefund(reqRefundPaylod).then((res: any) => {
          console.warn(res)
          this.isLoadingSubmit = false;
          if (res.responseInfo.responseCode) {
            console.log(res.responseInfo);

            this.requestForm.reset();
            this.dialog.closeAll();
            this.actionEmit.emit(res.responseInfo.responseCode);
            if (res.refundInfo) {
              this.refundCaseNoEmit.emit(res.refundInfo.refundCaseRefNo);
            }
          }
        })
      } else {
        // console.log('emit 999');
        this.actionEmit.emit('999');
      }
    }
  }

  checkValue(event: any) {
    let notAllowedKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

    if (notAllowedKeyCode.includes(event.keyCode)) {
      event.preventDefault();
    }
  }
}
