import { environment } from 'src/environments/environment';

export class ErrorMsg {
  public static readonly requireMobileNo = "Mobile no. is required";
  public static readonly invalidMobileNo = "Invalid phone number. Eg. (+60) 123456789";
  public static readonly requireEwalletId = "eWallet ID is required";
  public static readonly invalidEwalletId = "Invalid eWallet ID. Eg. (+60) 123456789";
  public static readonly requirePinCode = "Pin Code is required";
  public static readonly requireEmail = "E-mail is required";
  public static readonly invalidEmail = "Invalid e-mail format";
  public static readonly maxEmailLength = `E-mail cannot exceed ${environment.maxEmailLength} characters`;
  public static readonly accNotExist = "Account doesn't exist";
  public static readonly requirePassword = "Password is required";
  public static readonly requireCurrentPassword = "Current Password is required";
  public static readonly requireNewPassword = "New Password is required";
  public static readonly incorrectPassword = "Incorrect password";
  public static readonly minPassword = `Your password must contain ${environment.minPasswordLength} or more characters.`;
  public static readonly maxPassword = `Your password cannot exceed ${environment.maxPasswordLength} characters.`;
  public static readonly requireRetypePassword = "Retype password is required";
  public static readonly matchRetypePassword = "Please make sure your passwords match";
  public static readonly noCharPassword = " Your password must contain at least 1 upper and 1 lower case alphabet.";
  public static readonly noDigitPassword = "Your password must contain at least 1 number or 1 special character.";
  public static readonly maxFailedAttempts = "Max failed login attempts";
  public static readonly accountNotExist = "Account doesn't exist";
  public static readonly invalidICFormat = "Invalid ID number format";
  public static readonly minICLength = "Length of ID number is incorrect";
  public static readonly requireIC = "ID number is required";
  public static readonly underage = "Below minimum age requirements (18 years old)";
  public static readonly requireFullName = "Full name is required";
  public static readonly maxFullName = `Full name cannot exceed ${environment.maxFullNameLength} characters`;
  public static readonly requireRemark = "Remarks is required";
  public static readonly requireTagQuantity = "Quantity is required";
  public static readonly maxTagQuantity = "Maximum quantity is exceeded";
  public static readonly requireFirstName = "First name is required";
  public static readonly requireLastName = "Last name is required";
  public static readonly requireContactNo = "Contact number is required";
  public static readonly requireContactCountryCode = "Country code is required";
  public static readonly invalidName = "Invalid name format";
  public static readonly requireAcceptTnc = "Please accept terms & conditions";
  public static readonly requireReason = "Please state the reason";
  public static readonly requireCode = "Code is required";
  public static readonly invalidLengthCode = "Length of code is incorrect";

  // #region VEHICLE
  public static readonly requireVhType = "Vehicle type is required";
  public static readonly requireVhMake = "Vehicle make is required";
  public static readonly requireVhModel = "Vehicle model is required";
  public static readonly requireVhYear = "Vehicle manufacturing year is required";
  public static readonly requirePlateNo = "Vehicle plate number is required";
  public static readonly requireCRSPlateNo = "Enter your vehicle registration number.";
  public static readonly requireVehicleColor = "Vehicle colour is required";
  // #endregion

  // #region REPLACEMENT
  public static readonly requireReplaceAddress = "Address is required";
  public static readonly requireReplaceCity = "City is required";
  public static readonly requireReplaceState = "State is required";
  public static readonly requireReplacePostcode = "Postcode is required";
  public static readonly requireReplaceCountry = "Country is required";
  public static readonly invalidAddress = "Invalid address";
  public static readonly invalidCity = "Invalid city";
  // #endregion


  // #region RFID
  public static readonly invalidRFIDTagID = "Invalid RFID Tag ID";
  public static readonly requireTagPlacement = "Please specify where the tag is placed";
  public static readonly requireToll = "Please select a toll plaza";
  public static readonly requireTngCardSerialNo = "TNG card serial number is required";
  public static readonly invalidTngCardSerialNo = "Invalid TNG card serial number format";
  public static readonly requireReplacementReason = "Please specify replacement reason";
  // #endregion


  // #region TRANSACTION
  public static readonly requireEntryTrxDate = "Please specify the entry transaction date";
  public static readonly requireEntryTrxTime = "Please specify the entry transaction time";
  public static readonly requireExtTrxDate = "Please specify the exit transaction date";
  public static readonly requireExtTrxTime = "Please specify the exit transaction time";
  // #endregion

  // #region PAYMENT
  public static readonly requireCardType = "Card type is required";
  public static readonly requireCardNumber = "Card number is required";
  public static readonly requireExpiredDate = "Expiration date is required";
  public static readonly requireCVN = "CVN is required";
  // #endregion
}
