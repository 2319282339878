import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipCustomActionDirective } from './tooltip-custom-action.directive';

@NgModule({
  declarations: [TooltipCustomActionDirective],
  imports: [
    CommonModule
  ],
  exports: [
  	TooltipCustomActionDirective
  ]
})
export class TooltipCustomActionModule { }
