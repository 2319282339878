import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './api-service/authentication.service';
import { RfidService } from './api-service/rfid.service';
import { UserService } from './api-service/user.service';
import { CardPaymentInfo, PaymentInfo } from './models/payment';
import { SiteServiceService } from './services/site-service/site-service.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('submitpayment', { static: false }) paymentContent: ElementRef<HTMLElement>;
  @ViewChild('payment_form', { static: false }) paymentForm: ElementRef;
  @ViewChild('access_key', { static: false }) accessKey: ElementRef;
  @ViewChild('profile_id', { static: false }) profileId: ElementRef;
  @ViewChild('transaction_uuid', { static: false }) uuid: ElementRef;
  @ViewChild('signed_field_names', { static: false }) signedField: ElementRef;
  @ViewChild('unsigned_field_names', { static: false }) unsignedField: ElementRef;
  @ViewChild('signed_date_time', { static: false }) signedDate: ElementRef;
  @ViewChild('locale', { static: false }) locale: ElementRef;
  @ViewChild('override_customer_utc_offset', { static: false }) utcOffset: ElementRef;

  @ViewChild('bill_to_surname', { static: false }) billSurname: ElementRef;
  @ViewChild('bill_to_forename', { static: false }) billForename: ElementRef;
  @ViewChild('bill_to_email', { static: false }) billEmail: ElementRef;
  @ViewChild('bill_to_address_line1', { static: false }) billAddress1: ElementRef;
  @ViewChild('bill_to_address_line2', { static: false }) billAddress2: ElementRef;
  @ViewChild('bill_to_address_city', { static: false }) billCity: ElementRef;
  @ViewChild('bill_to_address_state', { static: false }) billState: ElementRef;
  @ViewChild('bill_to_address_country', { static: false }) billCountry: ElementRef;
  @ViewChild('bill_to_address_postal_code', { static: false }) billPostal: ElementRef;

  @ViewChild('transaction_type', { static: false }) trxType: ElementRef;
  @ViewChild('reference_number', { static: false }) refNo: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('currency', { static: false }) currency: ElementRef;
  @ViewChild('auth_trans_ref_no', { static: false }) authRefNo: ElementRef;
  @ViewChild('signature', { static: false }) signature: ElementRef;

  isProd: boolean = environment.production;
  trackingId: string = environment.trackingID;

  siteHeaderStatus: string = 'hide';
  layout: string = 'hide';


  getUserSub: Subscription;
  isPilot: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private metaTagService: Meta, private authService: AuthenticationService, private changeDetector: ChangeDetectorRef, private siteSrv: SiteServiceService, private route: ActivatedRoute, private renderer: Renderer, private userService: UserService, private rfidService: RfidService) {
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          if (this.isProd) {
            gtag('config', this.trackingId, { 'page_path': event.urlAfterRedirects });
          }
        }
      }
    );
  }

  ngOnInit() {
    // validate isPilot user 
    // this.validateIsPilot();

    //-- spinner and error modal demo --//
    this.siteSrv.setProgressSpinner(true);
    setTimeout(() => {
      this.siteSrv.setProgressSpinner(false);
    }, 1000);

    // const hasLogin: boolean = (this.authService.currentTokenValue != null);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        map(() => {
          const currPg = this.activatedRoute.firstChild;
          let pgTitle: string = currPg.snapshot.data['title'] || "TNG RFID Portal";

          this.titleService.setTitle(pgTitle);

          this.metaTagService.addTag({ name: 'url', content: window.location.origin });

          this.renderer.setElementClass(document.body, 'lock--', false);

          let pgPurpose: string = currPg.snapshot.data['purpose'];
          
          if (pgPurpose == 'payment-processing') {
            this.processPayment();
          }

          let route = this.route.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap(route => route.data)
      ).subscribe(data => {
        if (data['layout']) {
          this.layout = data['layout'];
        } else {
          this.layout = 'hide';
        }
      });

  }

  // private validateIsPilot() {
  //   this.authService.currAccessToken.subscribe((hasToken: any) => {
  //     // if (hasToken && ) {
  //       this.getUserSub = this.userService.getProfile().subscribe(
  //         (resp) => {
  //           this.isPilot = resp.isPilot;

  //           if (environment.IRS_whiteList) {
  //             if (this.isPilot) {
  //               this.rfidService.saveIsPilotValue(true);
  //             } else {
  //               this.rfidService.saveIsPilotValue(false);
  //             }
  //           } else {
  //             this.rfidService.saveIsPilotValue(true);
  //           }
  //         },
  //         (err) => {
  //         }
  //       );
  //     }
  //   });
  // }

  get getProgressSpinner(): boolean {
    return this.siteSrv.getProgressSpinner;
  }

  processPayment() {
    
    let stateHistory = history.state.data;

    if (stateHistory == undefined)
      this.router.navigate(["/buy-rfid-tag"]);
    else {
      let info: PaymentInfo = stateHistory["info"];
      this.paymentForm.nativeElement.action = info.paymentURL;

      let paymentInfo: CardPaymentInfo = info.cardPaymentInfo;
      this.accessKey.nativeElement.value = paymentInfo.accessKey;
      this.profileId.nativeElement.value = paymentInfo.profileId;
      this.uuid.nativeElement.value = paymentInfo.transactionUuid;
      this.signedField.nativeElement.value = paymentInfo.signedFieldNames;
      this.unsignedField.nativeElement.value = paymentInfo.unsignedFieldNames;
      this.signedDate.nativeElement.value = paymentInfo.signedDateTime;
      this.locale.nativeElement.value = paymentInfo.locale;
      this.utcOffset.nativeElement.value = paymentInfo.utcOffset;

      let billProfile = paymentInfo.billingProfile;
      this.billForename.nativeElement.value = billProfile.firstName;
      this.billSurname.nativeElement.value = billProfile.lastName;
      this.billEmail.nativeElement.value = billProfile.email;
      this.billAddress1.nativeElement.value = billProfile.address1;
      this.billAddress2.nativeElement.value = billProfile.address2;
      this.billCity.nativeElement.value = billProfile.city;
      this.billState.nativeElement.value = billProfile.state;
      this.billCountry.nativeElement.value = billProfile.country == 'MYS' ? 'MY' : billProfile.country;
      this.billPostal.nativeElement.value = billProfile.postcode;

      this.trxType.nativeElement.value = paymentInfo.transactionType;
      this.refNo.nativeElement.value = paymentInfo.referenceNumber;
      this.amount.nativeElement.value = paymentInfo.amount ? Number(paymentInfo.amount).toFixed(2) : 0.00;
      this.currency.nativeElement.value = paymentInfo.currency;
      this.authRefNo.nativeElement.value = paymentInfo.authTransRefNo;
      this.signature.nativeElement.value = paymentInfo.signature;

      this.paymentContent.nativeElement.click();
    }
  }

  checkURLIncludes(str: string) {
    if (this.router.url.includes(str)) {
      return true;
    }
    return false;
  }
}
