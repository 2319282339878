import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortListPipe implements PipeTransform {
  transform(value: any[], key?: string, asc: boolean = true): any[] {

    const array: any[] = value.sort((a: any, b: any): number => {
      if (!key) {
        return a > b ? 1 : -1;
      }

      return a[key] > b[key] ? 1 : -1;
    });

    if (asc)
      return array;
    else
      return array.reverse();
  }
}
