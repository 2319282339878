import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import sha256 from 'crypto-js/sha256';
import JSEncrypt from 'jsencrypt';

@Injectable({
    providedIn: 'root',
})
export class EncryptService {
    privateKey: string = environment.plrtpPrivateKey;

    constructor(){}

    sign(request: any) {
        let sign = new JSEncrypt({
            default_key_size: '2048'
        })
        sign.setPrivateKey(this.privateKey);
        let signature = sign.sign(request, sha256, "sha256"); 
        return signature;
    }
}