import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAction } from 'src/app/helpers/enums';

@Component({
  selector: 'action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss']
})
export class ActionModalComponent implements OnInit {

  @ViewChild('content', { static: false }) modalContent: TemplateRef<any>;

  header: string;
  description: string;
  confirmID: string;
  cancelID: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit() { }

  openModal(title: string, desc: string, confirmID: string, cancelID: string): Promise<ModalAction> {
    this.header = title;
    this.description = desc;
    this.confirmID = confirmID;
    this.cancelID = cancelID;

    let ref = this.dialog.open(this.modalContent);
    let result = new Promise<ModalAction>(resolve => {
      ref.afterClosed().subscribe(
        (result: ModalAction) => {
          return resolve(result ? result : ModalAction.Cancel);
        });
    });

    return result;
  }
}
