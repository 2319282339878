import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MasterdataService } from 'src/app/api-service/masterdata.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  getCustomerCountSub: Subscription;

  odometerCountTo: number = 0;  // counting value from

  forceRedirect: boolean = true;

  public topBannerSlickConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": true,
    "autoplaySpeed": 3000,
    "dots": true,
    "infinite": true,
  };

	public mxSolutionSlickConfig= {
		"centerMode": true,
	  "centerPadding": '0px',
    "slidesToShow": 3,
    "autoplay": true,
		"autoplaySpeed": 2000,
		"dots": true,
		"infinite": true,
		"arrows" : false,
		"responsive": [
	    {
	      "breakpoint": 1200,
	      "settings": {
	        "slidesToShow": 2,
	      }
	    },{
	      "breakpoint": 700,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '60px',
	      }
	    }, {
	      "breakpoint": 640,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '40px',
	      }
	    }, {
	      "breakpoint": 480,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '20px',
	      }
	    }
    ]
  };

  public simpleSolutionSlickConfig = {
    "centerMode": true,
    "centerPadding": '0px',
    "slidesToShow": 4,
    "initialSlide": 0,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "dots": true,
    "infinite": true,
    "arrows": false,
    "responsive": [
      {
        "breakpoint": 1200,
        "settings": {
          "slidesToShow": 2,
        }
      }, {
        "breakpoint": 700,
        "settings": {
          "slidesToShow": 2,
          "centerPadding": '60px',
        }
      }, {
        "breakpoint": 640,
        "settings": {
          "slidesToShow": 2,
          "centerPadding": '40px',
        }
      }, {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 1,
          "centerPadding": '20px',
        }
      }
    ]
  };

  public discoverTnGSlickConfig = {
    "centerMode": true,
    "centerPadding": '0',
    "slidesToShow": 2,
		"infinite": true,
		"autoplay": true,
		"autoplaySpeed": 2000,
		"responsive": [{
	      "breakpoint": 1200,
	      "settings": {
	      	"slidesToShow": 2,
	      	"centerPadding": '0px',
	      }
	    },{
	      "breakpoint": 1000,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '40px',
	      }
	    },{
	      "breakpoint": 800,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '0px',
	      }
	    },{
	      "breakpoint": 700,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '60px',
	      }
	    },{
	      "breakpoint": 500,
	      "settings": {
	      	"slidesToShow": 1,
	      	"centerPadding": '15px',
	      }
	    },
    ]
  };

  public odometerConfig = {
    // counting value from
    value: this.odometerCountTo,
    duration: 2000,
  }

  constructor(private masterDataAPI: MasterdataService, private router: Router) { }

  ngOnInit() {
    if (this.forceRedirect) {
      this.router.navigate(["/"]);
    }

    this.getCustomerCountSub = this.masterDataAPI.getNumberOfCustomer().subscribe(
      (resp) => this.odometerCountTo = resp.numberOfCustomers,
      (err) => {
        const msg: string = "Failed to get customer count.";
        console.error(msg + "\nERROR: " + err);
      });
  }

  ngOnDestroy() {
    if (this.getCustomerCountSub)
      this.getCustomerCountSub.unsubscribe();
  }
}
