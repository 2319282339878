import { BaseResponse } from './baseresponse';

export class Vehicle {
  id: string;
  vehiclePlateNumber: string;
  vehicleType: string;
  ownership: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleManufacturingYear: number;
  vehicleRegisteredCountry: string;
  isVehicleVerified: boolean;
  tagId: string;
  tagStatus: string;
  freeTag: boolean;
  vehicleStatus: string;
}

export class GetVehicleRequest {
  page: number;
  limit: number;

  constructor(pg: number, take: number) {
    this.page = pg;
    this.limit = take;
  }
}

export class GetVehicleResponse extends Vehicle {
}

export class GetVehicleDetailResponse extends BaseResponse {
  vehicle: Vehicle;
}

export class AddVehicleRequest {
  vehiclePlateNumber: string;
  vehicleType: string;
  ownership: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleManufacturingYear: number;
  vehicleRegisteredCountry: string;
}

export class AddVehicleResponse extends BaseResponse {
  vehicleId: string;
  vehiclePlateNumber: string;
}

export class UpdateVehicleRequest extends AddVehicleRequest {
  tagId: string

  constructor(vh: Vehicle) {
    super();
    this.tagId = vh.tagId || undefined;
    this.vehiclePlateNumber = vh.vehiclePlateNumber || undefined;
    this.vehicleType = vh.vehicleType || undefined;
    this.ownership = vh.ownership || undefined;
    this.vehicleMake = vh.vehicleMake || undefined;
    this.vehicleModel = vh.vehicleModel || undefined;
    this.vehicleManufacturingYear = vh.vehicleManufacturingYear || undefined;
    this.vehicleRegisteredCountry = vh.vehicleRegisteredCountry || undefined;
  }
}

export class UpdateVehicleResponse extends BaseResponse {
  vehicle: Vehicle
}

export class DeleteVehicleResponse extends BaseResponse {
}

export class ValidateVehicleNumberRequest {
  vehiclePlateNumber: string;
  ownership: boolean;
  dataSharingConsent: boolean;
}

export class ValidateVehicleResponse extends BaseResponse {
  vehicle: Vehicle;
}
