import { BaseResponse } from './baseresponse';

export class Customer {
  id: string;
  email: string;
  fullName: string;
  alternateEmail: string;
  idType: string;
  idNumber: string;
  isPilot: boolean;
  nationality: string;
  mobileCountryCode: string;
  mobileNo: string;
  dateOfBirth: Date;
  gender: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  dataSharingConsent: boolean;
  tngMarketingConsent: boolean;
  tcConsent: boolean;
  hasTempPwd: boolean;
  sofLoginId: number;
  customerAccountId: string;
  mfaEnabled: boolean;
  bindingId: string;
}

export class SimpleUser {
  email: string;
  fullName: string;
  mobileCountryCode: string;
  mobileNo: string;
  isPilot: boolean;

  constructor(user: Customer) {
    this.email = user.email;
    this.fullName = user.fullName;
    this.mobileCountryCode = user.mobileCountryCode;
    this.mobileNo = user.mobileNo;
    this.isPilot = user.isPilot;
  }
}

export class UserRequest extends Customer {
}

export class UserResponse extends Customer {
  is_pilot: any;
}

export class UpdateUserResponse extends BaseResponse {
  customer: Customer;
}

export class UserModel {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token?: string;
}

export class ChangeEmailRequest {
  oldEmail: string;
  newEmail: string;
  password: string;
}

export class ChangeEmailResponse extends BaseResponse {
}

export class ChangeWalletRequest {
  oldMobileCountryCode: string;
  oldMobileNo: string;
  newMobileCountryCode: string;
  newMobileNo: string;
  pin: string;
}

export class ChangeWalletResponse extends BaseResponse {
}

export class BindSofAccountRequest {
  idType: string;
  idNumber: string;
  customerId: string;
}

export class BindSofAccountResponse extends BaseResponse {
}
