import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StatusCode, StorageKey } from '../helpers/enums';
import { AccountActivationRequest, AccountActivationResponse, ChangePasswordRequest, ChangePasswordResponse, ForgotPasswordRequest, ForgotPasswordResponse, ForgotPasswordVerificationRequest, ForgotPasswordVerificationResponse, LoginRequest, LoginResponse, LogoutResponse, ResendEmailResponse, ResetPasswordRequest, ResetPasswordResponse, SignUpEmailRequest, SignUpEmailResponse, SignUpRequest, SignUpResponse, ChangeEmailVerificationRequest, ChangeEmailVerificationResponse } from '../models/authentication';
import sha256 from 'crypto-js/sha256';
import { MfaService } from './mfa.service';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;
// const apiURL2: string = environment.apiEndpoint2;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  //private currUserSubject: BehaviorSubject<LoginResponse>;
  private currAccessSubject: BehaviorSubject<string>;
  private currRefreshSubject: BehaviorSubject<string>;

  //public currUser: Observable<LoginResponse>;
  public currAccessToken: Observable<string>;
  public currRefresToken: Observable<string>;

  constructor(private httpClient: HttpClient, private mfaService: MfaService) {
    //this.currUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem(StorageKey.CurrUser)));
    //this.currUser = this.currUserSubject.asObservable();

    this.currAccessSubject = new BehaviorSubject<string>(localStorage.getItem(StorageKey.AccessToken));
    this.currAccessToken = this.currAccessSubject.asObservable();

    this.currRefreshSubject = new BehaviorSubject<string>(localStorage.getItem(StorageKey.RefreshToken));
    this.currRefresToken = this.currRefreshSubject.asObservable();
  }

  //public get currentUserValue(): LoginResponse {
  //  return this.currUserSubject.value;
  //}

  public get currAccessTokenValue() {

    return this.currAccessToken;
  }

  public get currentTokenValue(): string {
    const accessToken: string = localStorage.getItem(StorageKey.AccessToken);
    const refreshToken: string = localStorage.getItem(StorageKey.RefreshToken);

    return accessToken || refreshToken;
    //return (this.currAccessSubject.value ? this.currAccessSubject.value : this.currRefreshSubject.value);
  }

  public get currentMfaRequestedValue(): string {
    return localStorage.getItem(StorageKey.MfaRequested);
  }

  public get currentMfaVerifiedValue(): string {
    return localStorage.getItem(StorageKey.MfaVerified);
  }

  public get hasTempPassword(): boolean {
    const hasTemp: string = localStorage.getItem(StorageKey.HasTempPassword);
    return (hasTemp ? (hasTemp == "true") : false);
  }

  public setHasTempPassword(hasTemp: string, tempPass: string) {
    localStorage.setItem(StorageKey.HasTempPassword, hasTemp);

    if (tempPass != null)
      localStorage.setItem(StorageKey.TempPassword, tempPass);
    else
      localStorage.removeItem(StorageKey.TempPassword);
  }

  public removeAccessToken(): void {
    localStorage.removeItem(StorageKey.AccessToken);
    this.currAccessSubject.next(null);
  }

  public setAccessToken(result: any) {
    localStorage.setItem(StorageKey.AccessToken, result.access_token);
    this.currAccessSubject.next(result.access_token);

    localStorage.setItem(StorageKey.RefreshToken, result.refresh_token);
    this.currRefreshSubject.next(result.refresh_token);
  }

  hashString(pwd): string {
    let hashStr = sha256(pwd);
    return hashStr.toString();
  }

  public requestOtp(): Observable<any> {
    return this.httpClient.post<any>(`${apiURL}request-otp`, { headers: header }).pipe(map((resp) => {
      // localStorage.setItem(StorageKey.MfaRequested, 'true');
    }));
  }

  public verifyOtp(otp: any): Observable<any> {
    return this.httpClient.post<any>(`${apiURL}verify-otp`, otp, { headers: header }).pipe(map((resp) => {
      localStorage.setItem(StorageKey.MfaVerified, 'true');
    }));
  }

  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    if (internalTest) {
      return this.httpClient.get<LoginResponse>('assets/json/login.json')
        .pipe(map(user => {
          const result = user;
          //localStorage.setItem(StorageKey.CurrUser, JSON.stringify(result));
          //this.currUserSubject.next(result);
          localStorage.setItem(StorageKey.LoginEmail, loginRequest.email);

          localStorage.setItem(StorageKey.AccessToken, result.access_token);
          this.currAccessSubject.next(result.access_token);

          localStorage.setItem(StorageKey.RefreshToken, result.refresh_token);
          this.currRefreshSubject.next(result.refresh_token);

          return user;
        }));
    }
    else {
      return this.httpClient.post<any>(`${apiURL}authenticate`, loginRequest).pipe(
        map((user) => {
          const result = user;
          // Handle the response from the first API call here

          localStorage.setItem(StorageKey.AccessToken, result.access_token);
          this.currAccessSubject.next(result.access_token);

          localStorage.setItem(StorageKey.RefreshToken, result.refresh_token);
          this.currRefreshSubject.next(result.refresh_token);

          localStorage.setItem(StorageKey.MfaRequested, result.mfaEnabled);
          localStorage.setItem(StorageKey.OtpAttempt, result.otpAttempt);
          localStorage.setItem(StorageKey.MaxOtpAttempt, result.maxOtpAttempt);

          return user;

          // localStorage.setItem(StorageKey.AccessToken, result.access_token);
          // this.currAccessSubject.next(result.access_token);

          // localStorage.setItem(StorageKey.RefreshToken, result.refresh_token);
          // this.currRefreshSubject.next(result.refresh_token);

          // Perform another API call
        })
      );

      // return this.httpClient.post<any>(`${apiURL}authenticate`, loginRequest)
      //   .pipe(map(user => {
      //     const result = user;

      //     //localStorage.setItem(StorageKey.CurrUser, JSON.stringify(result));
      //     //this.currUserSubject.next(result);

      //     localStorage.setItem(StorageKey.AccessToken, result.access_token);
      //     this.currAccessSubject.next(result.access_token);

      //     localStorage.setItem(StorageKey.RefreshToken, result.refresh_token);
      //     this.currRefreshSubject.next(result.refresh_token);

      //     return user;
      //   }));
    }
  }


  // #region PASSWORD
  //  Reset Password - Verify Token
  forgotPasswordVerification(forgotPasswordVerificationRequest: ForgotPasswordVerificationRequest): Observable<ForgotPasswordVerificationResponse> {
    if (internalTest)
      return this.httpClient.get<ForgotPasswordVerificationResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.get<ForgotPasswordVerificationResponse>(`${apiURL}reset-password/${forgotPasswordVerificationRequest.token}`, { headers: header });
  }

  //  Reset Password - Update Password
  resetPassword(token: string, resetPasswordRequest: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    if (internalTest)
      return this.httpClient.get<ResetPasswordResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<ResetPasswordResponse>(`${apiURL}reset-password/${token}`, resetPasswordRequest, { headers: header });
  }

  //  Change Password
  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    if (internalTest)
      return this.httpClient.get<ChangePasswordResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.put<ChangePasswordResponse>(`${apiURL}me/change-password`, changePasswordRequest, { headers: header });
  }

  //  Forgot Password - Send Email
  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<ForgotPasswordResponse> {
    if (internalTest)
      return this.httpClient.get<ForgotPasswordResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<ForgotPasswordResponse>(`${apiURL}forgot-password`, forgotPasswordRequest, { headers: header });
  }
  // #endregion


  //  #region SIGN UP
  //  Verify Sign Up Email - to check whether applicant have TnGo account
  verifySignUpEmail(signUpEmailRequest: SignUpEmailRequest): Observable<SignUpEmailResponse> {
    if (internalTest) {
      let apiURL: string;
      switch (signUpEmailRequest.email) {
        case "new@new.com": apiURL = 'assets/json/sign-up/verify-email-new.json';
          break;
        case "tng@tng.com": apiURL = 'assets/json/sign-up/verify-email-tng.json';
          break;
        case "pending@pending.com": apiURL = 'assets/json/sign-up/verify-email-pending-activate.json';
          break;
        default: apiURL = 'assets/json/sign-up/verify-email-new.json';
          break;
      }

      return this.httpClient.get<SignUpEmailResponse>(apiURL, { headers: header });
    }
    else
      return this.httpClient.post<SignUpEmailResponse>(`${apiURL}sign-up-verify`, signUpEmailRequest, { headers: header });
  }


  //  Sign Up - after verify sign up email, applicant is new user
  signUp(signUpRequest: SignUpRequest): Observable<SignUpResponse> {
    if (internalTest)
      return this.httpClient.get<SignUpResponse>('assets/json/sign-up/signup.json', { headers: header });
    else
      return this.httpClient.post<SignUpResponse>(`${apiURL}sign-up`, signUpRequest, { headers: header });
  }

  //  Resend Activation Email - resend account activation email after sign up
  resendActivationEmail(signUpEmailRequest: SignUpEmailRequest): Observable<ResendEmailResponse> {
    if (internalTest)
      return this.httpClient.get<ResendEmailResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<ResendEmailResponse>(`${environment.apiEndpoint}resend-activation-email`, signUpEmailRequest, { headers: header });
  }
  //  #endregion

  emailActivation(accountActivationRequest: AccountActivationRequest): Observable<AccountActivationResponse> {
    if (internalTest)
      return this.httpClient.get<AccountActivationResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<AccountActivationResponse>(`${apiURL}activate-account`, accountActivationRequest, { headers: header });
  }

  //  Change Email - Verify Token
  changeEmailVerification(changeEmailVerificationRequest: ChangeEmailVerificationRequest): Observable<ChangeEmailVerificationResponse> {
    if (internalTest) {
      return this.httpClient.get<ChangeEmailVerificationResponse>('assets/json/baseresponse.json', { headers: header });
    }
    else
      return this.httpClient.get<ChangeEmailVerificationResponse>(`${apiURL}verify-email/${changeEmailVerificationRequest.token}`, { headers: header });
  }



  //  Logout
  logout(): Observable<LogoutResponse> {
    if (internalTest) {
      return this.httpClient.get<LogoutResponse>('assets/json/logoutresponse.json')
        .pipe(map(response => {
          if (response.statusCode == StatusCode.Success) {
            this.clearStorage();
          }
          return response;
        }));
    }
    else {
      return this.httpClient.post<any>(`${apiURL}sign-out`, null)
        .pipe(map(response => {
          if (response.statusCode == StatusCode.Success) {
            this.clearStorage();
          }
          return response;
        }));
    }
  }

  public clearStorage() {
    localStorage.clear();

    //this.currUserSubject.next(null);
    this.currAccessSubject.next(null);
    this.currRefreshSubject.next(null);
  }
}
