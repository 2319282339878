import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BindSOFRequest, ConfirmSOFRequest, ConfirmSOFResponse, GetReplacementTagResponse, LinkVehicleWithTagRequest, LinkVehicleWithTagResponse, PaginatedTagRequestHistoryResponse, RefundRequestModel, ReplacementTagActivationResponse, ReplacementTagRequest, ReplacementTagResponse, SOFRequest, SOFResponse, TagActivationResponse, TagHistoryRequest, TagResponse, TagStatusRequest, ValidateRFIDRequest, ValidateRFIDResponse } from '../models/rfid';

const header = new HttpHeaders({ 'Content-Type': 'application/json' });
const httpOptions = {
  headers: header
};
const internalTest: boolean = environment.isInternalTest;
const apiURL: string = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})

export class RfidService {

  private isShowRefundCTASubject = new BehaviorSubject<boolean>(false);
  public _isShowRefundCTA: Observable<boolean> = this.isShowRefundCTASubject.asObservable();

  private refundRequestFormSubject = new BehaviorSubject<any>('');
  public _refundRequestFormValue: Observable<any> = this.refundRequestFormSubject.asObservable();

  private vehicleNumberSubject = new BehaviorSubject<any>('');
  public _vehicleNumber: Observable<any> = this.vehicleNumberSubject.asObservable();

  private isPilotSubject = new BehaviorSubject<any>(false);
  public _isPilot: Observable<any> = this.isPilotSubject.asObservable();

  private showRefundSubject = new BehaviorSubject<any>(false);
  public _showRefund: Observable<any> = this.showRefundSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  ////  #region RFID Tag
  //  Validate RFID Tag - check inventory for the RFID tag status
  public validateRFIDTag(validateRFIDRequest: ValidateRFIDRequest): Observable<ValidateRFIDResponse> {
    if (internalTest)
      return this.httpClient.get<ValidateRFIDResponse>('assets/json/validaterfid.json', { headers: header });
    else
      return this.httpClient.post<SOFResponse>(`${apiURL}requests/validate-tag`, validateRFIDRequest, { headers: header });
  }
  ////  #endregion



  ////  #region Payment (SOF)
  //  Verify SOF - verify whether eWallet/ Source of Fund(SOF) is active
  public verifySOF(sofRequest: SOFRequest): Observable<SOFResponse> {
    if (internalTest)
      return this.httpClient.get<SOFResponse>('assets/json/sof.json', { headers: header });
    else
      return this.httpClient.post<SOFResponse>(`${apiURL}requests/verify-payment-method`, sofRequest, { headers: header });
  }

  //  SOF Confirmation (First-time) - confirm eWallet/SOF for the 1st time
  public confirmSOF(confirmReq: ConfirmSOFRequest): Observable<ConfirmSOFResponse> {
    if (internalTest)
      return this.httpClient.get<ConfirmSOFResponse>('assets/json/baseresponse.json', { headers: header });
    else
      return this.httpClient.post<ConfirmSOFResponse>(`${apiURL}requests/confirm-payment-method`, confirmReq, { headers: header });
  }

  //  Bind Existing SOF - bind SOF profile to current customer
  public bindSOF(sofReq: BindSOFRequest): Observable<SOFResponse> {
    if (internalTest)
      return this.httpClient.get<SOFResponse>('assets/json/sof.json', { headers: header });
    else
      return this.httpClient.post<SOFResponse>(`${apiURL}requests/bind-payment-method`, sofReq, { headers: header });
  }
  ////  #endregion



  ////  #region Update RFID Tag
  //  Link Vehicle With Tag
  public linkVehicleWithTag(vehicleID: string, linkVehicleWithTagRequest: LinkVehicleWithTagRequest): Observable<LinkVehicleWithTagResponse> {
    if (internalTest)
      return this.httpClient.get<LinkVehicleWithTagResponse>('assets/json/linkvehiclewithtag.json', { headers: header });
    else
      return this.httpClient.post<LinkVehicleWithTagResponse>(`${apiURL}requests/link-vehicle-with-tag/${vehicleID}`, linkVehicleWithTagRequest, { headers: header });
  }

  //  Submit Tag Request (Activation)
  public submitTagActivationRequest(vehicleID: string): Observable<TagActivationResponse> {
    if (internalTest)
      return this.httpClient.get<TagActivationResponse>('assets/json/tagactivation.json', { headers: header });
    else
      return this.httpClient.post<TagActivationResponse>(`${apiURL}requests/confirm-vehicle-tag/${vehicleID}`, null, { headers: header });
  }

  //  Submit Tag Request (Suspension/Termination)
  public submitTagRequest(vhId: string, tagId: string, tagRequest: TagStatusRequest): Observable<TagResponse> {
    if (internalTest)
      return this.httpClient.get<TagResponse>('assets/json/tagresponse.json', { headers: header });
    else
      return this.httpClient.post<TagResponse>(`${apiURL}tag-requests/${vhId}/${tagId}`, tagRequest, { headers: header });
  }

  //  Get List of Tag Request History
  public getTagRequests(tagReq: TagHistoryRequest): Observable<PaginatedTagRequestHistoryResponse> {
    if (internalTest) {
      return this.httpClient.get<PaginatedTagRequestHistoryResponse>('assets/json/vehicle-tag/paginated-tag-req.json', { headers: header })
        .pipe(map(resp => {
          let result: PaginatedTagRequestHistoryResponse = resp;

          const req = resp.tagRequest;
          let startIndex: number = (tagReq.page - 1) * tagReq.limit;
          let endIndex: number = tagReq.page * tagReq.limit;
          let trxList = req.slice(startIndex, endIndex);

          result.tagRequest = trxList;
          result.currentPage = tagReq.page;

          return result;
        }));
    }
    else
      return this.httpClient.get<PaginatedTagRequestHistoryResponse>(`${apiURL}tag-requests?startDate=${tagReq.startDate}&endDate=${tagReq.endDate}`, { headers: header });
  }

  //  Submit Free Tag Replacement Request
  public submitReplacementTagRequest(tagRequest: ReplacementTagRequest): Observable<ReplacementTagResponse> {
    if (internalTest)
      return this.httpClient.get<ReplacementTagResponse>('assets/json/vehicle-tag/freetagrequest.json', { headers: header });
    else
      return this.httpClient.post<ReplacementTagResponse>(`${apiURL}replacement-tag-requests`, tagRequest, { headers: header });
  }

  //  Get Free Tag Replacement Requests
  public getReplacementTagRequests(vehicleID: string): Observable<GetReplacementTagResponse> {
    let url: string;
    if (internalTest) {
      switch (vehicleID) {
        case "f5a31895-8337-4765-be8b-c0f947fe4c90": url = 'assets/json/vehicle-tag/getfreetagrequest-approved.json';
          break;
        case "f5a31895-8337-4765-be8b-c0f947fe4c81": url = 'assets/json/vehicle-tag/getfreetagrequest-rejected.json';
          break;
        case "f5a31895-8337-4765-be8b-c0f947fe4c91": url = 'assets/json/vehicle-tag/getfreetagrequest-null.json';
          break;
        default: url = 'assets/json/vehicle-tag/getfreetagrequest-approved.json';
          break;
      }
    } else {
      url = `${apiURL}replacement-tag-requests/${vehicleID}`;
    }

    return this.httpClient.get<GetReplacementTagResponse>(url, { headers: header });
  }

  //  Replacement Tag Activation
  public submitReplacementTagActivation(vehicleID: string, validateRFIDRequest: ValidateRFIDRequest): Observable<ReplacementTagActivationResponse> {
    if (internalTest)
      return this.httpClient.get<ReplacementTagActivationResponse>('assets/json/vehicle-tag/tagreplacementactivation.json', { headers: header });
    else
      return this.httpClient.post<ReplacementTagActivationResponse>(`${apiURL}requests/link-vehicle-replaced-tag/${vehicleID}`, validateRFIDRequest, { headers: header });
  }
  ////  #endregion

  //Request Refund behaviorer subject
  setShowRefundCTA(refundCTA: boolean) {
    this.isShowRefundCTASubject.next(refundCTA);
  }

  getShowRefundCTA() {
    return this._isShowRefundCTA;
  }

  saveRefundRequestFormValue(formValue: any) {
    this.refundRequestFormSubject.next(formValue);
  }

  // deprecated
  saveIsPilotValue(value: any) {
    this.isPilotSubject.next(value);
  }

  // deprecated
  getIsPilotValue() {
    return this._isPilot;
  }

  // deprecated
  clearIsPilotValue() {
    this.isPilotSubject.next(null);
  }

  getRefundRequestFormValue() {
    return this._refundRequestFormValue;
  }

  saveCustomerId(customerId: any) {
    localStorage.setItem('cid', customerId);
  }

  saveCustomerInfo(customerInfo: any) {
    localStorage.setItem('ci', customerInfo);
  }

  saveRefundCaseNo(refundCaseNo: any) {
    localStorage.setItem('refundCaseNo', refundCaseNo);
  }

  getVehicleNumber() {
    return this._vehicleNumber;
  }

  getShowRefundValue() {
    return this._showRefund;
  }

  getCustomerInfo() {
    return localStorage.getItem('ci')
  }

  saveVehicleNumber(vehicleNumber: any) {
    this.vehicleNumberSubject.next(vehicleNumber);
  }

  getCustomerId() {
    return localStorage.getItem('cid')
  }

  getRefundCaseNo() {
    return localStorage.getItem('refundCaseNo')
  }

  //Get vehicles
  public getVehicles() {
    return this.httpClient.get(`${apiURL}vehicles`).toPromise();
  }

  //Refund
  public createRefund(refundRequest: RefundRequestModel) {
    return this.httpClient.post(`${apiURL}create-refund`, refundRequest).toPromise();
  }

  public getRefund(refundPayload: any, refundQueryParams: any) {
    return this.httpClient.post(`${apiURL}get-all-refund?startDate=${refundQueryParams.startDate}&endDate=${refundQueryParams.endDate}&refundStatus=${refundQueryParams.refundStatus}&searchBy=${refundQueryParams.searchBy}`, refundPayload).toPromise();
  }

  public getMasterData() {
    return this.httpClient.get(`${apiURL}get-masterdata`).toPromise();
  }

  public downloadRefundPDF(refundPayload: any, refundQueryParams: any) {
    return this.httpClient.post(`${apiURL}download-refund-file?startDate=${refundQueryParams.startDate}&endDate=${refundQueryParams.endDate}&refundStatus=${refundQueryParams.refundStatus}&searchBy=${refundQueryParams.searchBy}`, refundPayload).toPromise();
  }

}
