import { Component, AfterViewInit, Input, HostListener, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements AfterViewInit {

  forceHide: boolean = true;

  @Input('footerLayout') layout = 'hide';

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    this.sidenavFooterScrollController();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    this.sidenavFooterScrollController();
  }

  privacyPolicy: string = environment.privacyPolicy;
  whistleblowingPolicy: string = environment.whistleblowingPolicy;
  antiBriCorrPolicy: string = environment.antiBriCorrPolicy;
  custServiceCharter: string = environment.custServiceCharter;
  feesCharges: string = environment.feesCharges;
  termsOfUse: string = environment.termsOfUse;
  productDisclosure: string = environment.productDisclosure;

  constructor(private eleRef: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => { this.sidenavFooterScrollController(), 0 });
  }

  sidenavFooterScrollController() {
    if (this.layout === 'sidenav' || this.layout === 'sidenav-forChild') {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
      const pageHeight = document.documentElement.scrollHeight || document.body.scrollHeight || 0;
      const viewportHeight = window.innerHeight;
      const minimalFooter = this.eleRef.nativeElement.querySelector('.l-footer-minimal');
      if (scrollTop + viewportHeight >= pageHeight - 122) {
        minimalFooter.classList.remove('hide--');
      } else {
        minimalFooter.classList.add('hide--');
      }
    }
  }
}
