import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-fitment',
  templateUrl: './self-fitment.component.html',
  styleUrls: ['./self-fitment.component.scss']
})
export class SelfFitmentComponent implements OnInit {
  activeButton = 0;

	public topBannerSlickConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "infinite": true,
    "arrows": false,
  };

  public selfFitmentSlickConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "arrows": false,
  };

  constructor() { }

  ngOnInit() {
  }

  showClick(event){
    this.activeButton = event;
  }

}
