import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rfid-tnc',
  templateUrl: './rfid-tnc.component.html',
  styleUrls: ['./rfid-tnc.component.scss']
})
export class RfidTncComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
