import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FormErrorComponent } from './form-error/form-error.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { StatusComponent } from './status/status.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { VehicleCardComponent } from './vehicle-card/vehicle-card.component';
import { ActionModalComponent } from './action-modal/action-modal.component';
import { ProgressStepperComponent } from './progress-stepper/progress-stepper.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TitleStatusComponent } from './title-status/title-status.component';

//mat
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { TooltipCustomActionModule } from '@directives/tooltip-custom-action/tooltip-custom-action.module';

//pipes
import { SortListPipe } from './custom-pipes/sort-list.pipe';
import { RefundStatusPipe } from './custom-pipes/refund-status.pipe';
import { RefundReasonPipe } from './custom-pipes/refund-reason.pipe';
import { RefundPaymentMethodPipe } from './custom-pipes/refund-payment-method.pipe';
import { UnifySuccessModalComponent } from './unify-success-modal/unify-success-modal.component';
import { UnifyErrorModalComponent } from './unify-error-modal/unify-error-modal.component';
import { RfidRequestModalComponent } from './rfid-request-modal/rfid-request-modal.component';
import { RefundHistoryModalComponent } from './refund-history-modal/refund-history-modal.component';
import { ShopeeRedirectModalComponent } from './shopee-redirect-modal/shopee-redirect-modal.component';

@NgModule({
  declarations: [
    FormErrorComponent,
    ProgressSpinnerComponent,
    StatusComponent,
    RecaptchaComponent,
    VehicleCardComponent,
    ActionModalComponent,
    ProgressStepperComponent,
    ErrorModalComponent,
    SortListPipe,
    RefundStatusPipe,
    RefundReasonPipe,
    RefundPaymentMethodPipe,
    BreadcrumbComponent,
    TitleStatusComponent,
    UnifySuccessModalComponent,
    UnifyErrorModalComponent,
    RfidRequestModalComponent,
    RefundHistoryModalComponent,
    ShopeeRedirectModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    TooltipCustomActionModule,

  ],
  exports: [
    RecaptchaComponent,
    VehicleCardComponent,
    ActionModalComponent,
    ProgressStepperComponent,
    ProgressSpinnerComponent,
    ErrorModalComponent,
    BreadcrumbComponent,
    SortListPipe,
    RefundStatusPipe,
    RefundReasonPipe,
    RefundPaymentMethodPipe,
    TitleStatusComponent,
    UnifySuccessModalComponent,
    UnifyErrorModalComponent,
    RfidRequestModalComponent,
    RefundHistoryModalComponent
  ]
})
export class SharedModuleModule { }
