import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'faq-view',
  templateUrl: './faq-view.component.html',
  styleUrls: ['./faq-view.component.scss']
})
export class FaqViewComponent {
	wheel = 0;

	public faqSlickConfig= {
    "slidesToShow": 7,
    "slidesToScroll":2,
		"infinite": false,
		"variableWidth": true,
		"responsive": [
			{
	      "breakpoint": 1300,
	      "settings": {
	      	"slidesToShow": 6,
	        "slidesToScroll": 3,
	      }
	    },{
	      "breakpoint": 1100,
	      "settings": {
	      	"slidesToShow": 5,
	        "slidesToScroll": 4,
	      }
	    },{
	      "breakpoint": 800,
	      "settings": {
	      	"slidesToShow": 3,
	        "slidesToScroll": 3,
	      }
	    },{
	      "breakpoint": 500,
	      "settings": {
	      	"slidesToShow": 3,
	        "slidesToScroll": 2,
	      }
	    },{
	      "breakpoint": 400,
	      "settings": {
	      	"slidesToShow": 1,
	        "slidesToScroll": 1,
	      }
	    }
	  ],
	};

	faqcategoriesList=[
	    'About TNG RFID',
	    'TNG RFID Tag Purchase',
		'TNG RFID Tag Self-fitment',
		'TNG RFID Tag Activation',
		'TNG RFID Tag Management',
		'My Account',
		'Payment Method',
		'Refund',
		'Transactions',
	]

	@ViewChild('faqSlickModal', {static: true}) faqSlickModal: SlickCarouselComponent;
	faqSelectedIndex = 0;

	onElementScroll($event) {

		if ($event.deltaY > 0) {
			this.faqSlickModal.slickPrev();
			$event.preventDefault();
		}

		else if ($event.deltaY < 0) {
			this.faqSlickModal.slickNext();
			$event.preventDefault();
		}
	}

  constructor() { }

  faqCategorySwitch(index:number){
  	this.faqSelectedIndex = index;
  	// this.faqSlick.slickGoTo(index);
  }
}
