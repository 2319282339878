import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorMsg } from 'src/app/helpers/contants';
import { OrderService } from '../../../api-service/order.service';
import { ErrorModalComponent } from '../../shared-module/error-modal/error-modal.component';
import { ShopeeRedirectModalComponent } from '../../shared-module/shopee-redirect-modal/shopee-redirect-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'buy-rfid',
  templateUrl: './buy-rfid.component.html',
  styleUrls: ['./buy-rfid.component.scss']
})
export class BuyRfidComponent implements OnInit, OnDestroy {

  @ViewChild(ErrorModalComponent, { static: false }) errorModal: ErrorModalComponent;

  getProductsSub: Subscription;

  tagPurchaseForm: FormGroup;

  isReady: boolean = false;
  currValue: number = 1;
  maxQtyLimit: number;
  isNoStock: boolean = false;
  productName: string;
  unitPrice: number;
  discUnitPrice: number;
  discRate: number;
  discount: boolean = false;
  discType: string;

  errList = {
    requireTagQuantity: ErrorMsg.requireTagQuantity,
    maxTagQuantity: ErrorMsg.maxTagQuantity
  }

  imageSrc = '/assets/images/views/buy-rfid/rfid-front.png';

  imageCards = [
    {
      src: '/assets/images/views/buy-rfid/rfid-front.png',
      thumb: '/assets/images/views/buy-rfid/rfid-front.png',
    }, {
      src: '/assets/images/views/buy-rfid/rfid-back.png',
      thumb: '/assets/images/views/buy-rfid/rfid-back-thumb.png',
    }, {
      src: '/assets/images/views/buy-rfid/rfid-how-to-install.png',
      thumb: '/assets/images/views/buy-rfid/rfid-how-to-install-thumb.png',
    }, {
      src: '/assets/images/views/buy-rfid/rfid-how-to-activate.png',
      thumb: '/assets/images/views/buy-rfid/rfid-how-to-activate-thumb.png',
    }
  ]

  constructor(private formBuilder: FormBuilder, private orderServiceAPI: OrderService, private dialog: MatDialog) { }

  ngOnInit() {
    this.constructForm();
  }

  ngOnDestroy() {
    if (this.getProductsSub)
      this.getProductsSub.unsubscribe();
  }

  get tpf() { return this.tagPurchaseForm.controls; }

  private async constructForm() {
    await this.getProduct();
    this.tagPurchaseForm = this.formBuilder.group({
      quantity: new FormControl('', [Validators.required, Validators.max(this.maxQtyLimit)])
    });
  }

  imageClick(imageNameObject) {
    this.imageSrc = imageNameObject.src;
  }

  submitPurchaseForm() {
    // const formData = this.tagPurchaseForm.value;

    // Open the modal when submitPurchaseForm() is called
    this.dialog.open(ShopeeRedirectModalComponent, {
      panelClass: 'shopee-redirect-modal'
    });
  }

  minusButton() {
    this.currValue = this.tagPurchaseForm.value.quantity;
    if (this.currValue > 1)
      this.currValue = this.currValue - 1;
  }

  plusButton() {
    this.currValue = this.tagPurchaseForm.value.quantity;
    if (this.currValue < this.maxQtyLimit)
      this.currValue = +this.currValue + 1;
  }

  private getProduct() {
    return new Promise((resolve, reject) => {
      this.getProductsSub = this.orderServiceAPI.getProductRequest().subscribe(
        (resp) => {
          let filterProduct = resp[0];
          this.productName = filterProduct.productName;
          this.maxQtyLimit = filterProduct.maxQtyLimit;

          if (filterProduct.stockAvailability == "O" && filterProduct.stockCount == 0) {
            this.currValue = 0;
            this.isNoStock = true;
          }

          if (filterProduct.discUnitPrice != filterProduct.unitPrice) {
            if (filterProduct.discUnitPrice > 0) {
              this.discount = true;
              this.discRate = filterProduct.unitDiscRate;
              this.discUnitPrice = filterProduct.discUnitPrice;
              this.discType = filterProduct.discountType;
            }
          }
          this.unitPrice = filterProduct.unitPrice;

          this.isReady = true;
          resolve(this.maxQtyLimit)
        },
        (err) => this.errorModal.openModal(err)
      );
    })
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
