import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteServiceService {

  private showProgressSpinner: boolean = false;

  private currentErrModalSubject: BehaviorSubject<boolean>;
  private showErrorModal: boolean = false;

  private errorModalMsg: string = '';

  constructor() {
    this.currentErrModalSubject = new BehaviorSubject<boolean>(this.showErrorModal);
  }

  setProgressSpinner(val: boolean) {
    this.showProgressSpinner = val;
  }

  get getProgressSpinner(): boolean {
    return this.showProgressSpinner;
  }

  setErrorModal(val: boolean, msg: string) {
    this.showErrorModal = val;
    this.errorModalMsg = msg;
  }

  public get getErrorModal(): Observable<boolean> {
    return this.currentErrModalSubject.asObservable();
  }

  //get getErrorModal(): boolean {
  //  return this.showErrorModal;
  //}

  get getErrorModalMsg(): string {
    return this.errorModalMsg;
  }
}
