import { Injectable } from "@angular/core";
import { Body, Headers, Request } from "../models/signature.model";
import { EncryptService } from "./encrypt.service";

@Injectable({
    providedIn: "root",
})
export class SignatureService {
    clientId: string;

    constructor(private encryptService: EncryptService) {
        this.clientId = '211020000000000000086';
    }

    get generateRandomAlphanumeric() {
        return Math.random().toString(36).slice(2,5);
    }

    signRequest(functionName: string, body: any) {
        let headers = new Headers();

        headers.requestId = this.generateRandomAlphanumeric;
        headers.clientId = this.clientId;
        headers.function = functionName;

        let signed = new Request();
        signed.header = headers;
        signed.body = body;

        let wrap = JSON.stringify(signed).replace(/\n\s+/g, '');

        let req = new Body();
        req.request = signed;
        req.signature = this.encryptService.sign(wrap);

        return req;
    }


}