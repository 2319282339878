import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../api-service/authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let token: string = this.authService.currentTokenValue;
    let cmsApiURL: string = environment.cmsEndpoint;
    //console.log("%c token = " + token, "color: purple;");
    //console.log("%c request = " + request.url, "color: blue;");

    if (token && token != null && request.url.indexOf(cmsApiURL) < 0) {
      //console.log("continue!!!");
      //this.authService.removeAccessToken();

      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }
    //console.log(" ");

    return next.handle(request);
  }
}
