import { Component, OnChanges, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: ['./progress-stepper.component.scss']
})
export class ProgressStepperComponent implements OnChanges, AfterViewInit {

	@ViewChild('progressBar', {static:true, read:ElementRef}) progressBar:ElementRef;
	@Input('stepsConfig') stepsConfig = [];
	@Input('currentStepTag') currentStepTag: string = '';
	currentStepIndex = 0;

  constructor() { }

  ngAfterViewInit(){
  	this.setCurrentStep(this.currentStepTag);
  }

  ngOnChanges() {
  	this.setCurrentStep(this.currentStepTag);
  }

  setCurrentStep(tag:string){
  	if(this.stepsConfig){
	  	var target = this.getStepFromTag(tag);
	  	if(target.length){
	  		var index = target[0].stepIndex;
		  	var progressPerc = 10;
		  	if(index>1){
		  		progressPerc = target[0].stepIndex;
          if(progressPerc>100){progressPerc = 100;}
		  	}
		  	this.currentStepIndex = target[0].stepIndex;
		  	this.progressBar.nativeElement.style.width = progressPerc+'%';
	  	}
	  }
  }

  getStepFromTag(tag:string) {
  	if(this.stepsConfig){
  		var target = this.stepsConfig.filter((el)=>{
  			return el.stepTag === tag;
  		});
  		return target;
  	}
  }

}
