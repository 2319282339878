import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'unify-error-modal',
  templateUrl: './unify-error-modal.component.html',
  styleUrls: ['./unify-error-modal.component.scss']
})
export class UnifyErrorModalComponent implements OnChanges {


  @Input('unifyErrorTitle') unifyErrorTitle: string = '';
  @Input('unifyErrorDesc') unifyErrorDesc: string = '';
  @Input('unifyErrorEmail') unifyErrorEmail: boolean = false;
  @Input('unifyIsBackToWebsite') unifyIsBackToWebsite: boolean = false;
  @Output() routerBtn: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('disabledClose') disabledClose: boolean = false;

  @ViewChild('unifyErrorModal', { static: false }) unifyErrorModal: TemplateRef<any>;

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnChanges() {
    // if (this.showErrorModal) {
    //   this.dialog.open(this.unifyErrorModal);
    // } else {
    //   this.modalClose();
    // }
  }
  openModal() {
    this.dialog.open(this.unifyErrorModal, {
      width: '720px', disableClose: this.disabledClose
    });
  }

  modalClose() {
    this.dialog.closeAll();
  }

  routerBtnClick() {
    // this.routerBtn.emit(true);
    window.location.href = 'https://www.touchngo.com.my'
  }
}
