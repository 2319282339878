import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'refundReason' })
export class RefundReasonPipe implements PipeTransform {
  transform(value: any) {
    switch (value) {
      case 'Overcharged - Penalty (CARD)':
      case '01': {
        return 'Penalty';
      }

      case 'Overcharged - Wrong Vehicle Class':
      case '02': {
        return 'Overcharged'
      }

      case 'DED - RFID & Pay Direct': 
      case '04': {
        return 'Double Deduction'
      }

      case '03':
      case '06': {
        return 'Unauthorized Usage';
      }

      case '05': {
        return 'DED - RFID & TNG Card';
      }

      case '08': {
        return 'DED - PayDirect & PayDirect';
      }

      case '09': {
        return 'DED - PayDirect & TNG card';
      }

      case '10': {
        return 'Missing value';
      }

      case '11': {
        return 'Card Termination';
      }

      case '12': {
        return 'Refund Replacement';
      }

      case 'B1': {
        return 'NEI with valid entry info';
      }

      case 'B2': {
        return 'Historical Transaction Pattern';
      }
    }
  }
}